main
	
	.owl-theme 
		.owl-controls 
			.owl-pagination
				position: absolute
				bottom: 5%
				right: 4%
				z-index: 999
				
				.owl-page
					
					vertical-align: middle
					
					span
						opacity: 1
						border: 2px solid #fff
						height: 15px
						background-color: #fff
						width: 15px

					&.active
						span
							background: #fff
							background: transparent
							height: 19px
							width: 19px
						
			.owl-buttons 
				div
					background: none;
					opacity: 1;
					top: calc( 50% - 50px )
					position: absolute;
					
					@media( max-width: 676px )
						display: none
					
					&:first-child
						left: 10px
						
						@media( max-width: 1023px )
							left: 30px
						
						@media( max-width: 500px )
							left: 10px
							
					&:last-child
						right: 10px
						
						@media( max-width: 1023px )
							right: 30px	
						
						@media( max-width: 500px )
							right: 10px				

					svg
						height: 100px
						fill: transparent
						*
							stroke: #fff
							fill: transparent
							
					&:hover
						svg
							*
								stroke: blue-color
	
	#slider
		
		.item
			background-size: cover
			background-repeat: no-repeat
			
			// @media (max-height: 414px)
			// 	height: calc( 100vh - 70px ) !important
			@media(max-width: 740px)
				height: 92vh !important

		.slider-txt			
			position: absolute;
			bottom: 75px;
			left: 75px;
			padding-right: 75px
			color: #fff
			
			@media( max-width: 1024px )
				left: 55px
				padding-right: 30px

			@media( max-width: 736px )
				left: 55px
				padding-right: 30px	

			@media ( max-width: 414px )
				left: 30px

			@media ( max-height: 414px )
				bottom: 30px
				left: 30px

			h1
				font-size: 60px
				font-weight: 900

				@media (min-width: 481px)
					font-size: 80px

				@media (min-width: 1681px)
					font-size: 119px
			
				@media (max-width: 480px)
					font-size: 34px
					
				@media ( max-height: 414px )
					font-size: 30px


			h2
				font-size: 26px
				font-weight: 500
			
				@media (max-width: 480px)
					font-size: 26px

				@media (min-width: 481px)
					font-size: 40px

				@media (min-width: 1681px)
					font-size: 56px
					
				@media ( max-height: 414px )
					font-size: 20px
					margin-top: 10px

			.btn-zaffiro
				margin-top: 56px

				@media ( max-width: 480px )
					span
						font-size: 16px

					button
						padding: 10px 7px

						svg
							height: 14px

				@media ( max-height: 414px )
					margin-top: 20px
					
					span
						font-size: 16px

					button
						padding: 10px 7px

						svg
							height: 8px

	
	.top-main-img
		position: relative
		width: 100%
		background-position: bottom left, center
		background-repeat: no-repeat, no-repeat
		background-size: auto, cover
		color: #fff

		div
			
			padding: 182px 30px 110px 30px
			
			@media (min-width: 1681px)
				padding: 358px 96px 110px 96px

			@media (min-width: 481px)
				padding: 182px 60px 60px 60px

			h1
				font-size: 60px
				font-weight: 900
			
				@media (min-width: 481px)
					font-size: 80px

				@media (min-width: 1681px)
					font-size: 119px



			h2
				font-size: 26px
				font-weight: 500
			
				@media (min-width: 481px)
					font-size: 40px

				@media (min-width: 1681px)
					font-size: 56px

			.btn-zaffiro
				margin-top: 56px

	.boxes-container
		width: 100%

		.row
			max-height: 100%
			margin: 0

			div 
				padding: 0

			.box
				padding: 0
				height: 700px
				background-size: cover
				overflow: hidden


				@media (max-width: 414px) 
					background-size: contain
					height: 133vh !important

				@media (max-height: 414px)
					height: 300vh !important

				h1
					font-size: 52px
					font-weight: 900
					margin-top: 0

					@media (min-width: 1600px)
						font-size: 63px

				.bg-container
					background-size: cover

				.txt-container
					background: #fff
					padding: 50px 40px 75px
					font-size: 20px

					@media (min-width: 1600px)
						font-size: 23px
						padding: 72px 56px


					@media (min-width: 1800px)
						font-size: 26px

					@media (max-width: 736px)
						padding: 50px 30px

				.half-logo
					height: 40px
					width: 48px
					position: absolute
					left: calc( 50% - 24px )
					top: calc( 50% - 20px )

					img
						max-width: 100%
						max-height: 100%

				.txt-container
					position: relative

				.btn-zaffiro
					position: absolute
					bottom: 56px
					left: 56px

					@media (max-width: 1600px)
						bottom: 40px
						left: 40px

					@media (max-width: 414px)
						left: 30px

					@media (max-width: 374px)
						position: relative
						bottom: 0
						left: 0
						margin-top: 20px

						span
							font-size: 16px

						button
							padding: 10px 7px

							svg
								height: 14px

					@media (max-height: 414px)
						position: relative
						bottom: 0
						left: 0
						margin-top: 20px

						span
							font-size: 16px

						button
							padding: 10px 7px

							svg
								height: 8px

				&.wide-box

					background-size: cover

					@media (max-width: 735px) 
						background-size: contain
						height: 97vh !important
						background-repeat: no-repeat

					@media (min-width: 735px) and (max-width:1200px)
						height:100vh !important

					@media (max-width: 540px)
						height:83vh !important

					@media (max-width: 390px)
						//height:55vh !important

					.row

						> div
							height: 100%

							.txt-container
								position: absolute
								color: #fff
								min-height: 50%

								@media(max-width: 1550px)
									padding: 50px 40px 75px

								@media(max-width: 736px)
									padding: 50px 30px
										
								@media (max-height: 414px)
									padding: 50px 30px
									min-height: 25%

								h1
									font-size: 40px
									margin-bottom: 21px

									@media (max-width: 1550px)
										font-size: 40px

									@media (max-width: 374px)
										font-size: 32px

									@media (min-width: 1600px)
										font-size: 46px

									@media (max-height: 414px)
										font-size: 30px

								> p
									margin: 0

									@media (max-width: 1550px)
										font-size: 20px

									@media (max-width: 374px)
										font-size: 16px

									@media (max-height: 414px)
										font-size: 20px

								&.bottom-txt
									background: light-steel-color
									width: 100%

									@media (min-width: 768px)
										bottom: 0
										margin-top: 100%

									@media (max-width: 767px)
										transform: translateY( -99% )

									h1
										color: blue-color

								&.top-txt
									background: blue-color
									width: 100%

									@media (min-width: 768px)
										top: 0

									@media (max-width: 767px)
										margin-top: 50vh
										width: 100%
									
									@media (max-width: 550px)
										margin-top: 40vh

									@media (max-width: 540px)
										margin-top: 36vh
									
									@media (max-width: 400px)
										position: absolute
										margin-top: 23vh
										//bottom: 0;

									@media (max-height: 400px)
										position: absolute
										bottom: 0
									

									h1
										color: #fff

				&.narrow-box
					color: blue-color

					h1
						color: steel-color

						@media (max-width: 1550px)
							font-size: 40px

						@media (max-width: 374px)
							font-size: 32px

						@media (max-height: 414px)
							font-size: 30px

					.txt-container
						> p

							@media (min-width: 768px)
								position: absolute
								top: 50%
								left: 0
								padding: 0 56px
								-ms-transform: translateY(-50%) /* IE 9 */
								-webkit-transform: translateY(-50%) /* Chrome, Safari, Opera */
								transform: translateY(-50%)

							@media (max-width: 1600px)
								font-size: 20px
								padding: 0 40px

							@media (max-width: 767px)
								padding: 0
								
							@media (max-width: 374px)
								font-size: 16px

							@media (max-height: 414px)
								font-size: 20px


					.content

						@media(max-width: 767px)
							width: 100%
							height: 50%

						@media(max-height: 414px)
							&.bg-container
								height: 75%
							&.txt-container
								height: 25%

						@media(max-width: 374px)
							&.bg-container
								height: 100%

							&.txt-container
								height: auto
								transform: translateY( -100% )

					.half-logo

						@media(max-width: 767px)
							display: none !important


				> div
					height: 100%

	#newsletter
		padding: 95px 0
		clear: both
		background: url("../img/main/main-site-bg.png") repeat
		color: blue-color
		font-size: 26px

		.container
			padding: 0 40px !important

			@media (max-width: 1199px)
				text-align: center
			
			> span
				display: block;

				@media (min-width: 1200px)
					float: left
				
		form
			width: 100%;

			@media( max-width: 1199px )
				display: inline-block
				clear:both
				padding-top: 30px
				padding-left: 14%
				width: 95%

			@media (max-width: 990px)
				padding-left: 0
			
			@media( max-width: 700px)
				//padding-left: 10%

			input
				margin-left: 35px
				margin-right: 24px
				width: 502px
				float: left
				max-width: 100%
				
				@media( max-width: 1199px )
					margin-left: 0
				
				@media (max-width: 990px)
					width: 73%

				@media (max-width: 630px)
					width: 69%
				@media (max-width: 550px)
					width: 80%

		.btn-zaffiro
			display: block
			position: static
			padding-top: 7px
			
			@media( max-width: 990px )
				clear: both
			
			@media( max-width: 550px )
				padding-top: 30px

	.owl-item
		width: 100%

	.owl-dots
		display: block !important
		position: absolute
		bottom: 30px
		right: 30px

		span
			width: 15px !important
			height: 15px !important