.container-404

	h1
		font-size: 487px
		text-align: center
		background: url('../img/main/404bg.png') no-repeat calc(50% - 419px) center
		line-height: 400px
		margin-top: 100px
		margin-bottom: 20px

		&:first-letter
			color: blue-color

	.no-page-found
		font-size: 18px
		text-align: center

		a
			color: light-steel-color

	.go-to-pages
		margin-top: 20px
		padding-bottom: 100px

		.row
			height: auto

			h3
				font-size: 30px
				font-weight: bold
				text-align: center
				color: blue-color

			p
				padding-top: 10px
				font-size: 14px
				color: light-steel-color
				text-align: center