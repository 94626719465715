a:hover,
a:focus,
a:active {
  text-decoration: none;
}
.no-scroll {
  position: fixed;
  overflow: hidden;
  width: 100%;
}
body {
  font-family: 'Source Sans Pro', sans-serif;
}
body .btn-zaffiro {
  display: inline-flex !important;
  align-items: center;
  text-decoration: none;
}
body .btn-zaffiro .ico-holder {
  display: inline-block;
  border-radius: 3px;
  border: 0;
}
body .btn-zaffiro-white:hover .ico-holder {
  background: #4e576d;
}
body .btn-zaffiro-white:hover .ico-holder svg path {
  fill: #fff;
}
body .btn-zaffiro-white .ico-holder {
  background: #fff;
  -webkit-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}
body .btn-zaffiro-white .ico-holder svg path {
  fill: #4e576d;
  -webkit-transition: fill 200ms linear;
  transition: fill 200ms linear;
}
body .btn-zaffiro-white span {
  color: #fff;
}
body .btn-zaffiro-blue:hover .ico-holder {
  background: #fff;
}
body .btn-zaffiro-blue:hover .ico-holder svg path {
  fill: #4e576d;
}
body .btn-zaffiro-blue .ico-holder {
  background: #4e576d;
  -webkit-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}
body .btn-zaffiro-blue .ico-holder svg path {
  fill: #fff;
  -webkit-transition: fill 200ms linear;
  transition: fill 200ms linear;
}
body .btn-zaffiro-blue span {
  color: #4e576d;
}
body .btn-zaffiro-small {
  line-height: 0;
}
body .btn-zaffiro-small .ico-holder {
  padding: 9px 9px !important;
  width: 31px;
}
body .btn-zaffiro-small .ico-holder svg {
  height: 11px;
  display: block;
}
body .btn-zaffiro-small span {
  padding-left: 11px;
  font-size: 19px;
}
body .btn-zaffiro-big .ico-holder {
  padding: 13px 13px;
  max-width: 58px;
}
@media (min-width: 1681px) {
  body .btn-zaffiro-big .ico-holder {
    padding: 17px 17px;
  }
}
@media (min-width: 481px) {
  body .btn-zaffiro-big .ico-holder {
    padding: 17px 17px;
  }
}
body .btn-zaffiro-big .ico-holder svg {
  height: 20px;
  display: block;
}
body .btn-zaffiro-big span {
  padding-left: 10px;
  font-size: 23px;
}
@media (min-width: 1681px) {
  body .btn-zaffiro-big span {
    padding-left: 21px;
    font-size: 33px;
  }
}
@media (min-width: 481px) {
  body .btn-zaffiro-big span {
    padding-left: 15px;
    font-size: 28px;
  }
}
body button.btn-zaffiro {
  border-radius: 3px;
  border: 0;
}
body button.btn-zaffiro-white {
  background: #fff;
  color: #fff;
  transition: all 0.4s ease;
}
body button.btn-zaffiro-white:hover {
  background: #babec7;
}
body button.btn-zaffiro-blue {
  background: #4e576d;
  color: #fff;
  transition: all 0.4s ease;
}
body button.btn-zaffiro-blue:hover {
  background: #abb2bb;
}
body button.btn-zaffiro-small {
  line-height: initial;
  padding: 13px 34px;
  font-size: 15px;
  font-weight: bold;
}
@media (max-width: 380px) {
  body button.btn-zaffiro-small {
    padding: 13px 18px;
  }
}
@media (max-width: 350px) {
  body button.btn-zaffiro-small {
    padding: 13px 5px;
  }
}
body button.btn-zaffiro-big {
  padding: 22px 17px;
}
body .button-group button:first-child {
  float: left;
  margin-right: 14px;
}
@media (max-width: 350px) {
  body .button-group button:first-child {
    margin-right: 7px;
  }
}
body .button-group button:last-child {
  padding: 13px 12px;
}
body .button-group button img {
  height: 14px;
}
body footer {
  padding: 45px 0 0 0;
  color: #4a566c;
  background: #babec7;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}
@media (max-width: 1024px) {
  body footer {
    padding-left: 55px;
  }
}
@media (max-width: 736px) {
  body footer {
    padding-left: 30px;
  }
}
body footer .scroll-top {
  display: block;
  position: absolute;
  right: 45px;
  top: -43px;
  cursor: pointer;
}
body footer .scroll-top img {
  width: 45px;
  height: 45px;
}
body footer a {
  color: #4a566c;
}
body footer a:hover,
body footer a:visited,
body footer a:focus {
  text-decoration: none;
  color: #4a566c;
}
body footer > div {
  display: block;
  float: left;
  color: #4e576d;
  font-size: 14px;
  margin-bottom: 45px;
  padding: 0 20px;
}
@media (min-width: 1401px) and (max-width: 1601px) {
  body footer > div {
    padding: 0 10px;
  }
}
@media (max-width: 1024px) {
  body footer > div {
    padding: 0 55px 0 0;
  }
}
@media (max-width: 736px) {
  body footer > div {
    padding: 0 30px 0 0;
  }
}
body footer > div h3 {
  margin: 0 0 29px 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}
body footer > div h4 {
  margin: 0 0 29px 0;
  font-size: 14px;
}
body footer .footer-zaffiro-logo {
  width: 13.5%;
  text-align: center;
}
@media (max-width: 1440px) {
  body footer .footer-zaffiro-logo {
    width: 150px;
  }
}
@media (max-width: 355px) {
  body footer .footer-zaffiro-logo {
    width: 100%;
    text-align: left;
  }
}
body footer .footer-zaffiro-logo img {
  width: 116px;
  margin-top: 13px;
}
body footer .footer-womar-logo {
  width: 11.3%;
  text-align: center;
}
@media (max-width: 1440px) {
  body footer .footer-womar-logo {
    width: calc(100% - 150px);
    text-align: left;
  }
}
@media (max-width: 1024px) {
  body footer .footer-womar-logo {
    text-align: left;
  }
}
@media (max-width: 355px) {
  body footer .footer-womar-logo {
    width: 100%;
  }
}
body footer .footer-womar-logo img {
  width: 124px;
  margin-top: 13px;
}
body footer .footer-address {
  width: 12.6%;
}
@media (max-width: 1440px) {
  body footer .footer-address {
    width: 25%;
  }
}
@media (max-width: 650px) {
  body footer .footer-address {
    width: 100%;
  }
}
body footer .footer-contact {
  width: 12.55%;
}
@media (max-width: 1440px) {
  body footer .footer-contact {
    width: 25%;
  }
}
@media (max-width: 650px) {
  body footer .footer-contact {
    width: 100%;
  }
  body footer .footer-contact h3 {
    display: none;
  }
}
body footer .footer-menu {
  width: 8.3%;
}
@media (max-width: 1440px) {
  body footer .footer-menu {
    width: 25%;
  }
}
@media (max-width: 650px) {
  body footer .footer-menu {
    width: 40%;
  }
}
body footer .footer-menu ul,
body footer .footer-linki ul {
  padding: 0;
  margin: 0;
}
body footer .footer-menu ul li,
body footer .footer-linki ul li {
  list-style-type: none;
}
body footer .footer-linki {
  width: 9.94%;
}
@media (max-width: 1440px) {
  body footer .footer-linki {
    width: 25%;
  }
}
@media (max-width: 650px) {
  body footer .footer-linki {
    width: 60%;
  }
}
body footer .footer-sklep {
  width: 10.83%;
}
@media (max-width: 1440px) {
  body footer .footer-sklep {
    width: 50%;
  }
  body footer .footer-sklep > div {
    width: 32%;
    float: right;
  }
}
@media (max-width: 1024px) {
  body footer .footer-sklep {
    width: 25%;
  }
  body footer .footer-sklep > div {
    width: 100%;
    float: left;
  }
}
@media (max-width: 650px) {
  body footer .footer-sklep {
    width: 100%;
  }
  body footer .footer-sklep > div {
    width: 100%;
    max-width: 150px;
  }
}
body footer .footer-sklep .btn {
  width: 100%;
  text-align: center;
  background: #4e576d;
  color: #fff;
  padding: 8px 12px;
}
body footer .footer-newsletter {
  width: 19.5%;
}
@media (max-width: 1440px) {
  body footer .footer-newsletter {
    width: 50%;
  }
  body footer .footer-newsletter > div {
    width: 49%;
    display: inline-block;
  }
}
@media (max-width: 1024px) {
  body footer .footer-newsletter {
    width: 75%;
  }
}
@media (max-width: 650px) {
  body footer .footer-newsletter {
    width: 100%;
  }
  body footer .footer-newsletter > div:first-element {
    width: 70%;
  }
  body footer .footer-newsletter > div:last-element {
    width: 30%;
  }
}
@media (max-width: 570px) {
  body footer .footer-newsletter > div {
    width: 100%;
  }
}
body footer .footer-newsletter input {
  width: calc(100% - 100px);
  float: left;
}
body footer .footer-newsletter button {
  background: #4e576d;
  height: 38px;
  margin-left: 11px;
  color: #fff;
}
body footer .footer-newsletter .footer-socials {
  margin-top: 20px;
}
body footer .footer-newsletter .footer-socials a {
  padding-right: 14px;
}
body footer .pleo-footer {
  position: absolute;
  right: 35px;
  bottom: 25px;
  outline: 0;
  text-decoration: none;
}
body footer .pleo-footer span {
  padding-right: 10px;
  font-size: 12px;
}
body footer .pleo-footer svg {
  display: inline-block;
  float: right;
  margin-top: 3px;
}
body footer .row {
  margin-left: 0;
  margin-right: 0;
}
body input[type=text],
body textarea.form-control {
  border: 2px solid #e4e4e4;
}
body input[type=text] {
  height: 38px;
}
body .zaffiro-submit,
body .zaffiro-submit:hover,
body .zaffiro-submit:active {
  background: #4e576d;
  padding: 16px 36px;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
}
body .pos-goraLewo {
  background-position: left top;
}
body .pos-goraSrodek {
  background-position: center top;
}
body .pos-goraPrawo {
  background-position: right top;
}
body .pos-srodekLewo {
  background-position: left center;
}
body .pos-srodekSrodek {
  background-position: center center;
}
body .pos-srodekPrawo {
  background-position: right center;
}
body .pos-dolLewo {
  background-position: left bottom;
}
body .pos-dolSrodek {
  background-position: center bottom;
}
body .pos-dolPrawo {
  background-position: right bottom;
}
body .newsletter-form {
  margin-top: -3px;
}
.horizontal-mobile {
  position: fixed;
  z-index: 999999999;
  background-color: #fff;
  width: 100%;
  height: 200%;
  top: -50%;
  left: 0;
  display: none;
  padding: 35px 50px;
}
.horizontal-mobile > div {
  height: 100%;
}
.horizontal-mobile > div img {
  position: absolute;
  top: 28%;
  left: 0;
  width: 100px;
}
.horizontal-mobile > div .txt {
  left: 0;
  bottom: 30%;
  position: absolute;
}
.horizontal-mobile > div .txt h1 {
  font-size: 48px;
  font-weight: 900;
  color: #abb2bb;
}
.horizontal-mobile > div .txt h1 span {
  color: #4e576d;
}
.horizontal-mobile > div .txt p {
  color: #4e576d;
  font-size: 14px;
}
.horizontal-mobile > div .svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.newsletter-popup {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255,255,255,0.9);
  width: 100%;
  height: 100%;
  z-index: 10000000000;
}
.newsletter-popup .newsletter-popup-container {
  width: 60%;
  background: #4e576d;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.newsletter-popup .newsletter-popup-container header {
  padding: 30px 30px 30px;
  position: relative;
  height: auto;
}
.newsletter-popup .newsletter-popup-container header h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 700;
}
.newsletter-popup .newsletter-popup-container header > span {
  display: block;
  background: transparent;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 22px;
  height: 22px;
  color: #fff;
  opacity: 1;
}
.newsletter-popup .newsletter-popup-container section {
  padding: 30px 30px 15px;
  font-size: 16px;
}
.newsletter-popup .newsletter-popup-container .sign {
  padding: 30px 30px 60px;
}
.newsletter-popup .newsletter-popup-container .sign svg {
  width: 20px;
}
.newsletter-popup .newsletter-popup-container .sign .st0 {
  fill: #fff !important;
}
.newsletter-popup .newsletter-form {
  margin-top: -3px;
}
header {
  height: 165px;
  width: 100%;
}
@media (max-width: 1600px) {
  header {
    height: 135.5px;
  }
}
@media (max-width: 736px) {
  header {
    height: 70px;
    padding: 0;
  }
  header #header-right {
    margin-top: 24.5px !important;
  }
}
header > div {
  background-color: #fff;
  width: 100%;
  padding: 40px;
  z-index: 1999999;
  -webkit-transition: height 0.2s ease-in-out /* Safari */;
  transition: height 0.2s ease-in-out;
}
@media (max-width: 1600px) {
  header > div {
    padding: 25px 40px;
  }
}
@media (max-width: 736px) {
  header > div {
    height: 70px;
    padding: 0;
  }
  header > div > div {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
header.fixed > div,
header.scroll > div {
  position: fixed;
}
header.scroll #header {
  border-bottom: 1px solid #ededed;
}
header.scroll > div {
  height: 70px;
  padding: 0 40px;
}
@media (max-width: 736px) {
  header.scroll > div {
    padding: 0;
  }
}
header #header-logo {
  height: 85px;
  width: 172px;
  overflow-x: hidden;
  overflow-y: visible;
  float: left;
  position: relative;
  -webkit-transition: width 0.2s ease-in-out /* Safari */;
  transition: width 0.2s ease-in-out;
}
@media (max-width: 736px) {
  header #header-logo {
    height: 36px;
    width: 99px;
    overflow-y: hidden;
    margin-top: 17px;
  }
}
header #header-logo .logo-big,
header #header-logo .logo-small {
  position: absolute;
  -webkit-transition: opacity 0.2s ease-in-out /* Safari */;
  transition: opacity 0.2s ease-in-out;
}
header #header-logo .logo-big {
  width: 172px;
  max-width: 100%;
  top: 0;
  left: 0;
  opacity: 1;
}
header #header-logo .logo-small {
  height: 18px;
  top: 23px;
  left: 0;
  opacity: 0;
}
header.scroll > div #header-logo {
  width: 50px;
  margin-top: 2.5px;
  min-height: 70px;
}
header.scroll > div #header-logo .logo-big {
  opacity: 0;
}
header.scroll > div #header-logo .logo-small {
  opacity: 1;
}
header.scroll > div #header-right {
  margin-top: 22px;
}
header #header-right {
  margin-top: 32px;
}
header #header-right #header-socials {
  color: #babec7;
  font-weight: 900;
  margin-right: 44px;
}
@media (max-width: 667px) {
  header #header-right #header-socials {
    display: none;
  }
}
header #header-right #header-socials a {
  color: #babec7;
}
header #header-right #header-socials img {
  margin-left: 14px;
  margin-top: -4px;
}
header #header-right #header-menu-show {
  cursor: pointer;
}
header #header-right #header-menu-show span.txt {
  margin-left: 17px;
  font-weight: 900;
  color: #4e576d;
}
header #header-right #header-menu-show .hamburger {
  margin-top: 3px;
  float: left;
  width: 23px;
  height: 14px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
header #header-right #header-menu-show .hamburger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 50%;
  background: #4e576d;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
header #header-right #header-menu-show .hamburger span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}
header #header-right #header-menu-show .hamburger span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}
header #header-right #header-menu-show .hamburger span:nth-child(1),
header #header-right #header-menu-show .hamburger span:nth-child(2) {
  top: 0px;
}
header #header-right #header-menu-show .hamburger span:nth-child(3),
header #header-right #header-menu-show .hamburger span:nth-child(4) {
  top: 6px;
}
header #header-right #header-menu-show .hamburger span:nth-child(5),
header #header-right #header-menu-show .hamburger span:nth-child(6) {
  top: 12px;
}
header #header-right #header-menu-show .hamburger.open span:nth-child(1),
header #header-right #header-menu-show .hamburger.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
header #header-right #header-menu-show .hamburger.open span:nth-child(2),
header #header-right #header-menu-show .hamburger.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
header #header-right #header-menu-show .hamburger.open span:nth-child(1) {
  left: 2px;
  top: 2px;
}
header #header-right #header-menu-show .hamburger.open span:nth-child(2) {
  left: calc(50% - 2px);
  top: 2px;
}
header #header-right #header-menu-show .hamburger.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}
header #header-right #header-menu-show .hamburger.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}
header #header-right #header-menu-show .hamburger.open span:nth-child(5) {
  left: 2px;
  top: 10px;
}
header #header-right #header-menu-show .hamburger.open span:nth-child(6) {
  left: calc(50% - 2px);
  top: 10px;
}
header + nav {
  top: 165px;
}
@media (max-width: 1600px) {
  header + nav {
    top: 135px;
  }
}
@media (max-width: 736px) {
  header + nav {
    top: 70px;
  }
}
header + nav .submenu {
  height: calc(100% - 165px);
}
header.scroll + nav {
  top: 70px;
}
@media (min-width: 1000px) {
  header.scroll + nav .submenu {
    height: calc(100% - 70px);
  }
}
nav {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1999999;
  left: 0;
  display: none;
}
@media (max-width: 579px) {
  nav {
    overflow-y: auto;
    height: calc(100% - 70px);
  }
}
nav ul {
  list-style-type: none;
}
@media (min-width: 1000px) {
  nav > ul {
    padding: 110px 0 210px;
    width: 30%;
    border-right: 2px solid #babec7;
  }
}
@media (min-width: 580px) and (max-width: 999px) {
  nav > ul {
    display: table;
    width: calc(100% - 110px);
    border-bottom: 2px solid #babec7;
    margin: 0 55px;
    padding: 0;
  }
}
@media (max-width: 580px) {
  nav > ul {
    margin: 0 55px;
    padding: 0;
  }
}
@media (min-width: 1000px) and (max-width: 1140px) {
  nav > ul li {
    padding-left: 20px;
  }
}
@media (min-width: 1140px) and (max-width: 1300px) {
  nav > ul li {
    padding-left: 40px;
  }
}
@media (min-width: 1300px) {
  nav > ul li {
    padding-left: 76px;
  }
}
@media (min-width: 580px) and (max-width: 999px) {
  nav > ul li {
    display: table-cell;
    text-align: center;
    width: 14%;
  }
  nav > ul li:nth-child(7) {
    width: 15.5%;
  }
}
@media (max-width: 580px) {
  nav > ul li {
    clear: both;
  }
}
nav > ul li a {
  color: #4e576d;
}
@media (min-width: 1000px) {
  nav > ul li a {
    font-size: 26px;
    padding-left: 30px;
  }
}
@media (min-width: 580px) and (max-width: 999px) {
  nav > ul li a {
    font-size: 15px;
    padding: 29px 10px;
    display: block;
  }
}
@media (max-width: 580px) {
  nav > ul li a {
    padding-left: 0;
  }
}
nav > ul li a:hover {
  font-weight: bold;
  color: #4e576d;
}
nav > ul li a:hover,
nav > ul li a:visited,
nav > ul li a:active {
  text-decoration: none;
}
nav > ul li .submenu {
  padding: 20px 3%;
}
nav > ul li .submenu .row {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1200px) {
  nav > ul li .submenu .row {
    padding-top: 65px;
  }
}
nav > ul li .submenu .row .col-xs-3:nth-last-child(-n + 4 ) table.menu-ico-list {
  margin-bottom: 0 !important;
}
@media (min-width: 645px) and (max-width: 1000px) {
  nav > ul li .submenu .row .col-xs-3 {
    width: 19.5%;
  }
}
@media (min-width: 1200px) {
  nav > ul li .submenu .row .col-xs-3 {
    width: 19.5%;
  }
}
@media (max-width: 426px) {
  nav > ul li .submenu .row .col-xs-3 {
    width: 33%;
  }
}
@media (min-width: 999px) and (max-width: 1200px) {
  nav > ul li .submenu .row .col-xs-3 {
    width: 24.5%;
  }
}
@media (min-width: 580px) and (max-width: 645px) {
  nav > ul li .submenu .row .col-xs-3 {
    width: 24.5%;
  }
}
@media (min-width: 580px) {
  nav > ul li .submenu {
    overflow-y: auto;
    position: absolute;
  }
}
@media (min-width: 1000px) {
  nav > ul li .submenu {
    top: 0;
    left: 30%;
    width: 69%;
  }
}
@media (min-width: 580px) and (max-width: 999px) {
  nav > ul li .submenu {
    top: 100px;
    left: 0;
    width: 100%;
    padding: 0 55px;
  }
}
@media (max-width: 579px) {
  nav > ul li .submenu {
    border-bottom: 2px solid #babec7;
    border-top: 2px solid #babec7;
    position: absolute;
    top: 310px;
    height: auto;
    left: 0;
    margin: 30px 20px 40px 20px;
  }
  nav > ul li .submenu .col-xs-3 {
    width: 33%;
  }
}
nav > ul li .submenu a {
  font-size: 12px;
  font-weight: normal;
}
nav > ul li .submenu a:hover,
nav > ul li .submenu a.active {
  font-weight: bold;
}
nav > ul li .submenu a > div {
  padding: 0;
}
nav > ul li .submenu a table.menu-ico-list {
  width: 100%;
}
@media (min-width: 1000px) {
  nav > ul li .submenu a table.menu-ico-list {
    margin-bottom: 40px;
    height: 53px;
  }
}
@media (min-width: 580px) and (max-width: 999px) {
  nav > ul li .submenu a table.menu-ico-list {
    height: 106px;
  }
}
@media (max-width: 579px) {
  nav > ul li .submenu a table.menu-ico-list {
    text-align: center;
  }
}
@media (min-width: 1000px) {
  nav > ul li .submenu a table.menu-ico-list tr:last-child {
    display: none;
  }
}
@media (max-width: 999px) {
  nav > ul li .submenu a table.menu-ico-list tr:first-child td:last-child {
    display: none;
  }
}
@media (max-width: 999px) {
  nav > ul li .submenu a table.menu-ico-list tr td {
    height: 53px;
  }
}
@media (min-width: 1000px) {
  nav > ul li .submenu a table.menu-ico-list tr td img {
    height: 53px;
  }
}
@media (min-width: 500px) and (max-width: 999px) {
  nav > ul li .submenu a table.menu-ico-list tr td img {
    height: 100px;
  }
}
@media (max-width: 499px) {
  nav > ul li .submenu a table.menu-ico-list tr td img {
    height: 60px;
  }
}
nav > ul li .submenu a table.menu-ico-list .ico {
  width: 53px;
  text-align: center;
}
nav > ul li .submenu a table.menu-ico-list .ico img {
  max-width: 100%;
  max-height: 100%;
}
nav > ul li .submenu a table.menu-ico-list .name {
  padding: 0 12px;
  word-wrap: break-word;
}
nav > ul > li #header-socials a {
  padding-left: 19px;
}
nav > ul > li #header-socials a:first-child {
  padding-left: 0;
}
@media (min-width: 580px) {
  nav > ul > li #header-socials {
    display: none;
  }
}
@media (min-width: 1000px) {
  nav > ul > li > a {
    line-height: 52px;
  }
}
@media (min-width: 580px) and (max-width: 714px) {
  nav > ul > li > a {
    font-size: 12px;
    margin: 0;
    padding: 29px 0;
  }
}
@media (max-width: 580px) {
  nav > ul > li > a {
    margin: 20px 0;
    display: block;
  }
}
nav > ul > li > a:hover,
nav > ul > li > a.active {
  font-weight: bold;
}
@media (min-width: 1000px) {
  nav > ul > li > a:hover,
  nav > ul > li > a.active {
    background: url("../img/main/zaffiro-sign.svg") no-repeat left top 11px;
    background-size: 16px 16px;
  }
}
main .owl-theme .owl-controls .owl-pagination {
  position: absolute;
  bottom: 5%;
  right: 4%;
  z-index: 999;
}
main .owl-theme .owl-controls .owl-pagination .owl-page {
  vertical-align: middle;
}
main .owl-theme .owl-controls .owl-pagination .owl-page span {
  opacity: 1;
  border: 2px solid #fff;
  height: 15px;
  background-color: #fff;
  width: 15px;
}
main .owl-theme .owl-controls .owl-pagination .owl-page.active span {
  background: #fff;
  background: transparent;
  height: 19px;
  width: 19px;
}
main .owl-theme .owl-controls .owl-buttons div {
  background: none;
  opacity: 1;
  top: calc(50% - 50px);
  position: absolute;
}
@media (max-width: 676px) {
  main .owl-theme .owl-controls .owl-buttons div {
    display: none;
  }
}
main .owl-theme .owl-controls .owl-buttons div:first-child {
  left: 10px;
}
@media (max-width: 1023px) {
  main .owl-theme .owl-controls .owl-buttons div:first-child {
    left: 30px;
  }
}
@media (max-width: 500px) {
  main .owl-theme .owl-controls .owl-buttons div:first-child {
    left: 10px;
  }
}
main .owl-theme .owl-controls .owl-buttons div:last-child {
  right: 10px;
}
@media (max-width: 1023px) {
  main .owl-theme .owl-controls .owl-buttons div:last-child {
    right: 30px;
  }
}
@media (max-width: 500px) {
  main .owl-theme .owl-controls .owl-buttons div:last-child {
    right: 10px;
  }
}
main .owl-theme .owl-controls .owl-buttons div svg {
  height: 100px;
  fill: transparent;
}
main .owl-theme .owl-controls .owl-buttons div svg * {
  stroke: #fff;
  fill: transparent;
}
main .owl-theme .owl-controls .owl-buttons div:hover svg * {
  stroke: #4e576d;
}
main #slider .item {
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 740px) {
  main #slider .item {
    height: 92vh !important;
  }
}
main #slider .slider-txt {
  position: absolute;
  bottom: 75px;
  left: 75px;
  padding-right: 75px;
  color: #fff;
}
@media (max-width: 1024px) {
  main #slider .slider-txt {
    left: 55px;
    padding-right: 30px;
  }
}
@media (max-width: 736px) {
  main #slider .slider-txt {
    left: 55px;
    padding-right: 30px;
  }
}
@media (max-width: 414px) {
  main #slider .slider-txt {
    left: 30px;
  }
}
@media (max-height: 414px) {
  main #slider .slider-txt {
    bottom: 30px;
    left: 30px;
  }
}
main #slider .slider-txt h1 {
  font-size: 60px;
  font-weight: 900;
}
@media (min-width: 481px) {
  main #slider .slider-txt h1 {
    font-size: 80px;
  }
}
@media (min-width: 1681px) {
  main #slider .slider-txt h1 {
    font-size: 119px;
  }
}
@media (max-width: 480px) {
  main #slider .slider-txt h1 {
    font-size: 34px;
  }
}
@media (max-height: 414px) {
  main #slider .slider-txt h1 {
    font-size: 30px;
  }
}
main #slider .slider-txt h2 {
  font-size: 26px;
  font-weight: 500;
}
@media (max-width: 480px) {
  main #slider .slider-txt h2 {
    font-size: 26px;
  }
}
@media (min-width: 481px) {
  main #slider .slider-txt h2 {
    font-size: 40px;
  }
}
@media (min-width: 1681px) {
  main #slider .slider-txt h2 {
    font-size: 56px;
  }
}
@media (max-height: 414px) {
  main #slider .slider-txt h2 {
    font-size: 20px;
    margin-top: 10px;
  }
}
main #slider .slider-txt .btn-zaffiro {
  margin-top: 56px;
}
@media (max-width: 480px) {
  main #slider .slider-txt .btn-zaffiro span {
    font-size: 16px;
  }
  main #slider .slider-txt .btn-zaffiro button {
    padding: 10px 7px;
  }
  main #slider .slider-txt .btn-zaffiro button svg {
    height: 14px;
  }
}
@media (max-height: 414px) {
  main #slider .slider-txt .btn-zaffiro {
    margin-top: 20px;
  }
  main #slider .slider-txt .btn-zaffiro span {
    font-size: 16px;
  }
  main #slider .slider-txt .btn-zaffiro button {
    padding: 10px 7px;
  }
  main #slider .slider-txt .btn-zaffiro button svg {
    height: 8px;
  }
}
main .top-main-img {
  position: relative;
  width: 100%;
  background-position: bottom left, center;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, cover;
  color: #fff;
}
main .top-main-img div {
  padding: 182px 30px 110px 30px;
}
@media (min-width: 1681px) {
  main .top-main-img div {
    padding: 358px 96px 110px 96px;
  }
}
@media (min-width: 481px) {
  main .top-main-img div {
    padding: 182px 60px 60px 60px;
  }
}
main .top-main-img div h1 {
  font-size: 60px;
  font-weight: 900;
}
@media (min-width: 481px) {
  main .top-main-img div h1 {
    font-size: 80px;
  }
}
@media (min-width: 1681px) {
  main .top-main-img div h1 {
    font-size: 119px;
  }
}
main .top-main-img div h2 {
  font-size: 26px;
  font-weight: 500;
}
@media (min-width: 481px) {
  main .top-main-img div h2 {
    font-size: 40px;
  }
}
@media (min-width: 1681px) {
  main .top-main-img div h2 {
    font-size: 56px;
  }
}
main .top-main-img div .btn-zaffiro {
  margin-top: 56px;
}
main .boxes-container {
  width: 100%;
}
main .boxes-container .row {
  max-height: 100%;
  margin: 0;
}
main .boxes-container .row div {
  padding: 0;
}
main .boxes-container .row .box {
  padding: 0;
  height: 700px;
  background-size: cover;
  overflow: hidden;
}
@media (max-width: 414px) {
  main .boxes-container .row .box {
    background-size: contain;
    height: 133vh !important;
  }
}
@media (max-height: 414px) {
  main .boxes-container .row .box {
    height: 300vh !important;
  }
}
main .boxes-container .row .box h1 {
  font-size: 52px;
  font-weight: 900;
  margin-top: 0;
}
@media (min-width: 1600px) {
  main .boxes-container .row .box h1 {
    font-size: 63px;
  }
}
main .boxes-container .row .box .bg-container {
  background-size: cover;
}
main .boxes-container .row .box .txt-container {
  background: #fff;
  padding: 50px 40px 75px;
  font-size: 20px;
}
@media (min-width: 1600px) {
  main .boxes-container .row .box .txt-container {
    font-size: 23px;
    padding: 72px 56px;
  }
}
@media (min-width: 1800px) {
  main .boxes-container .row .box .txt-container {
    font-size: 26px;
  }
}
@media (max-width: 736px) {
  main .boxes-container .row .box .txt-container {
    padding: 50px 30px;
  }
}
main .boxes-container .row .box .half-logo {
  height: 40px;
  width: 48px;
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 20px);
}
main .boxes-container .row .box .half-logo img {
  max-width: 100%;
  max-height: 100%;
}
main .boxes-container .row .box .txt-container {
  position: relative;
}
main .boxes-container .row .box .btn-zaffiro {
  position: absolute;
  bottom: 56px;
  left: 56px;
}
@media (max-width: 1600px) {
  main .boxes-container .row .box .btn-zaffiro {
    bottom: 40px;
    left: 40px;
  }
}
@media (max-width: 414px) {
  main .boxes-container .row .box .btn-zaffiro {
    left: 30px;
  }
}
@media (max-width: 374px) {
  main .boxes-container .row .box .btn-zaffiro {
    position: relative;
    bottom: 0;
    left: 0;
    margin-top: 20px;
  }
  main .boxes-container .row .box .btn-zaffiro span {
    font-size: 16px;
  }
  main .boxes-container .row .box .btn-zaffiro button {
    padding: 10px 7px;
  }
  main .boxes-container .row .box .btn-zaffiro button svg {
    height: 14px;
  }
}
@media (max-height: 414px) {
  main .boxes-container .row .box .btn-zaffiro {
    position: relative;
    bottom: 0;
    left: 0;
    margin-top: 20px;
  }
  main .boxes-container .row .box .btn-zaffiro span {
    font-size: 16px;
  }
  main .boxes-container .row .box .btn-zaffiro button {
    padding: 10px 7px;
  }
  main .boxes-container .row .box .btn-zaffiro button svg {
    height: 8px;
  }
}
main .boxes-container .row .box.wide-box {
  background-size: cover;
}
@media (max-width: 735px) {
  main .boxes-container .row .box.wide-box {
    background-size: contain;
    height: 97vh !important;
    background-repeat: no-repeat;
  }
}
@media (min-width: 735px) and (max-width: 1200px) {
  main .boxes-container .row .box.wide-box {
    height: 100vh !important;
  }
}
@media (max-width: 540px) {
  main .boxes-container .row .box.wide-box {
    height: 83vh !important;
  }
}
main .boxes-container .row .box.wide-box .row > div {
  height: 100%;
}
main .boxes-container .row .box.wide-box .row > div .txt-container {
  position: absolute;
  color: #fff;
  min-height: 50%;
}
@media (max-width: 1550px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container {
    padding: 50px 40px 75px;
  }
}
@media (max-width: 736px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container {
    padding: 50px 30px;
  }
}
@media (max-height: 414px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container {
    padding: 50px 30px;
    min-height: 25%;
  }
}
main .boxes-container .row .box.wide-box .row > div .txt-container h1 {
  font-size: 40px;
  margin-bottom: 21px;
}
@media (max-width: 1550px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container h1 {
    font-size: 40px;
  }
}
@media (max-width: 374px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container h1 {
    font-size: 32px;
  }
}
@media (min-width: 1600px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container h1 {
    font-size: 46px;
  }
}
@media (max-height: 414px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container h1 {
    font-size: 30px;
  }
}
main .boxes-container .row .box.wide-box .row > div .txt-container > p {
  margin: 0;
}
@media (max-width: 1550px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container > p {
    font-size: 20px;
  }
}
@media (max-width: 374px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container > p {
    font-size: 16px;
  }
}
@media (max-height: 414px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container > p {
    font-size: 20px;
  }
}
main .boxes-container .row .box.wide-box .row > div .txt-container.bottom-txt {
  background: #babec7;
  width: 100%;
}
@media (min-width: 768px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container.bottom-txt {
    bottom: 0;
    margin-top: 100%;
  }
}
@media (max-width: 767px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container.bottom-txt {
    transform: translateY(-99%);
  }
}
main .boxes-container .row .box.wide-box .row > div .txt-container.bottom-txt h1 {
  color: #4e576d;
}
main .boxes-container .row .box.wide-box .row > div .txt-container.top-txt {
  background: #4e576d;
  width: 100%;
}
@media (min-width: 768px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container.top-txt {
    top: 0;
  }
}
@media (max-width: 767px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container.top-txt {
    margin-top: 50vh;
    width: 100%;
  }
}
@media (max-width: 550px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container.top-txt {
    margin-top: 40vh;
  }
}
@media (max-width: 540px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container.top-txt {
    margin-top: 36vh;
  }
}
@media (max-width: 400px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container.top-txt {
    position: absolute;
    margin-top: 23vh;
  }
}
@media (max-height: 400px) {
  main .boxes-container .row .box.wide-box .row > div .txt-container.top-txt {
    position: absolute;
    bottom: 0;
  }
}
main .boxes-container .row .box.wide-box .row > div .txt-container.top-txt h1 {
  color: #fff;
}
main .boxes-container .row .box.narrow-box {
  color: #4e576d;
}
main .boxes-container .row .box.narrow-box h1 {
  color: #abb2bb;
}
@media (max-width: 1550px) {
  main .boxes-container .row .box.narrow-box h1 {
    font-size: 40px;
  }
}
@media (max-width: 374px) {
  main .boxes-container .row .box.narrow-box h1 {
    font-size: 32px;
  }
}
@media (max-height: 414px) {
  main .boxes-container .row .box.narrow-box h1 {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  main .boxes-container .row .box.narrow-box .txt-container > p {
    position: absolute;
    top: 50%;
    left: 0;
    padding: 0 56px;
    -ms-transform: translateY(-50%) /* IE 9 */;
    -webkit-transform: translateY(-50%) /* Chrome, Safari, Opera */;
    transform: translateY(-50%);
  }
}
@media (max-width: 1600px) {
  main .boxes-container .row .box.narrow-box .txt-container > p {
    font-size: 20px;
    padding: 0 40px;
  }
}
@media (max-width: 767px) {
  main .boxes-container .row .box.narrow-box .txt-container > p {
    padding: 0;
  }
}
@media (max-width: 374px) {
  main .boxes-container .row .box.narrow-box .txt-container > p {
    font-size: 16px;
  }
}
@media (max-height: 414px) {
  main .boxes-container .row .box.narrow-box .txt-container > p {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  main .boxes-container .row .box.narrow-box .content {
    width: 100%;
    height: 50%;
  }
}
@media (max-height: 414px) {
  main .boxes-container .row .box.narrow-box .content.bg-container {
    height: 75%;
  }
  main .boxes-container .row .box.narrow-box .content.txt-container {
    height: 25%;
  }
}
@media (max-width: 374px) {
  main .boxes-container .row .box.narrow-box .content.bg-container {
    height: 100%;
  }
  main .boxes-container .row .box.narrow-box .content.txt-container {
    height: auto;
    transform: translateY(-100%);
  }
}
@media (max-width: 767px) {
  main .boxes-container .row .box.narrow-box .half-logo {
    display: none !important;
  }
}
main .boxes-container .row .box > div {
  height: 100%;
}
main #newsletter {
  padding: 95px 0;
  clear: both;
  background: url("../img/main/main-site-bg.png") repeat;
  color: #4e576d;
  font-size: 26px;
}
main #newsletter .container {
  padding: 0 40px !important;
}
@media (max-width: 1199px) {
  main #newsletter .container {
    text-align: center;
  }
}
main #newsletter .container > span {
  display: block;
}
@media (min-width: 1200px) {
  main #newsletter .container > span {
    float: left;
  }
}
main #newsletter form {
  width: 100%;
}
@media (max-width: 1199px) {
  main #newsletter form {
    display: inline-block;
    clear: both;
    padding-top: 30px;
    padding-left: 14%;
    width: 95%;
  }
}
@media (max-width: 990px) {
  main #newsletter form {
    padding-left: 0;
  }
}
main #newsletter form input {
  margin-left: 35px;
  margin-right: 24px;
  width: 502px;
  float: left;
  max-width: 100%;
}
@media (max-width: 1199px) {
  main #newsletter form input {
    margin-left: 0;
  }
}
@media (max-width: 990px) {
  main #newsletter form input {
    width: 73%;
  }
}
@media (max-width: 630px) {
  main #newsletter form input {
    width: 69%;
  }
}
@media (max-width: 550px) {
  main #newsletter form input {
    width: 80%;
  }
}
main #newsletter .btn-zaffiro {
  display: block;
  position: static;
  padding-top: 7px;
}
@media (max-width: 990px) {
  main #newsletter .btn-zaffiro {
    clear: both;
  }
}
@media (max-width: 550px) {
  main #newsletter .btn-zaffiro {
    padding-top: 30px;
  }
}
main .owl-item {
  width: 100%;
}
main .owl-dots {
  display: block !important;
  position: absolute;
  bottom: 30px;
  right: 30px;
}
main .owl-dots span {
  width: 15px !important;
  height: 15px !important;
}
.maps {
  padding-right: 0;
  padding-left: 0;
}
.maps > div {
  height: 700px !important;
}
@media (max-width: 767px) {
  .maps {
    margin-top: 30px;
  }
  .maps > div {
    height: 320px !important;
  }
}
.top-normal-img {
  height: 700px;
  position: relative;
}
.top-normal-img > .container-fluid > .row {
  margin: 0 -15px;
}
.top-normal-img .row > div {
  height: 100%;
  min-height: 100%;
}
@media (max-width: 736px) {
  .top-normal-img .row > div {
    height: auto;
    min-height: 0;
  }
}
.top-normal-img .row > div.img-container {
  height: 700px;
  background-size: cover;
}
.top-normal-img .row > div.txt-container {
  padding: 109px 55px;
  color: #abb2bb;
  font-size: 28px;
}
@media (max-width: 1024px) {
  .top-normal-img .row > div.txt-container {
    padding: 45px 55px;
  }
}
@media (max-width: 736px) {
  .top-normal-img .row > div.txt-container {
    padding: 45px 30px;
  }
}
@media (max-width: 414px) {
  .top-normal-img .row > div.txt-container {
    padding: 30px;
  }
}
.top-normal-img .half-logo {
  left: calc(25% - 24px);
  top: calc(50% - 20px);
}
@media (max-width: 1199px) {
  .top-normal-img .half-logo {
    left: calc(33.46% - 25px);
  }
}
.top-normal-img,
.standard-container-right,
.standard-container-left,
.standard-container {
  font-size: 20px;
  color: #4e576d;
}
.top-normal-img h1,
.standard-container-right h1,
.standard-container-left h1,
.standard-container h1 {
  font-size: 52px;
  font-weight: 900;
  margin-bottom: 66px;
  margin-top: 0;
  color: #abb2bb;
}
@media (max-width: 736px) {
  .top-normal-img h1,
  .standard-container-right h1,
  .standard-container-left h1,
  .standard-container h1 {
    margin-bottom: 40px;
    font-size: 40px;
  }
}
.top-normal-img h1 span,
.standard-container-right h1 span,
.standard-container-left h1 span,
.standard-container h1 span {
  color: #4e576d;
}
.standard-container-right,
.standard-container-left,
.standard-container {
  overflow: hidden;
  min-height: 700px;
  position: relative;
}
@media (max-width: 736px) {
  .standard-container-right,
  .standard-container-left,
  .standard-container {
    min-height: 0;
  }
}
.standard-container-right.gray,
.standard-container-left.gray,
.standard-container.gray {
  background: #f5fafe;
}
.standard-container-right > .row,
.standard-container-left > .row,
.standard-container > .row {
  margin: 0;
}
.standard-container-right .row,
.standard-container-left .row,
.standard-container .row {
  height: 700px;
  margin-right: 0;
}
.standard-container-right .row > div,
.standard-container-left .row > div,
.standard-container .row > div {
  height: 100%;
}
.standard-container-right .row > div.img-container,
.standard-container-left .row > div.img-container,
.standard-container .row > div.img-container {
  background-size: cover;
}
.standard-container-right .row > div.txt-container,
.standard-container-left .row > div.txt-container,
.standard-container .row > div.txt-container {
  padding: 74px 56px;
  color: #4e576d;
  font-size: 26px;
}
@media (max-width: 1024px) {
  .standard-container-right .row > div.txt-container,
  .standard-container-left .row > div.txt-container,
  .standard-container .row > div.txt-container {
    padding: 45px 55px;
  }
}
@media (max-width: 736px) {
  .standard-container-right .row > div.txt-container,
  .standard-container-left .row > div.txt-container,
  .standard-container .row > div.txt-container {
    padding: 45px 30px;
  }
}
@media (max-width: 414px) {
  .standard-container-right .row > div.txt-container,
  .standard-container-left .row > div.txt-container,
  .standard-container .row > div.txt-container {
    padding: 30px;
  }
}
.standard-container-right .row > div.txt-container h1,
.standard-container-left .row > div.txt-container h1,
.standard-container .row > div.txt-container h1 {
  font-size: 46px;
  font-weight: 900;
  margin-bottom: 283px;
  color: #abb2bb;
}
@media (max-width: 991px) {
  .standard-container-right .row > div.txt-container h1,
  .standard-container-left .row > div.txt-container h1,
  .standard-container .row > div.txt-container h1 {
    margin-bottom: 0;
  }
}
@media (max-width: 736px) {
  .standard-container-right .row > div.txt-container h1,
  .standard-container-left .row > div.txt-container h1,
  .standard-container .row > div.txt-container h1 {
    font-size: 39px;
  }
}
@media (max-width: 414px) {
  .standard-container-right .row > div.txt-container h1,
  .standard-container-left .row > div.txt-container h1,
  .standard-container .row > div.txt-container h1 {
    margin-bottom: 21px !important;
  }
}
.standard-container-right .row > div.txt-container h1 span,
.standard-container-left .row > div.txt-container h1 span,
.standard-container .row > div.txt-container h1 span {
  color: #4e576d;
}
.standard-container-right .btn-zaffiro,
.standard-container-left .btn-zaffiro,
.standard-container .btn-zaffiro {
  position: absolute;
  bottom: 71px;
  left: 54px;
}
@media (max-width: 1024px) {
  .standard-container-right .btn-zaffiro,
  .standard-container-left .btn-zaffiro,
  .standard-container .btn-zaffiro {
    left: 55px;
  }
}
@media (max-width: 736px) {
  .standard-container-right .btn-zaffiro,
  .standard-container-left .btn-zaffiro,
  .standard-container .btn-zaffiro {
    left: 30px;
  }
}
.half-logo {
  height: 40px;
  width: 48px;
  position: absolute;
}
.half-logo img {
  max-width: 100%;
  max-height: 100%;
}
#gdzie-kupic .top-normal-img {
  height: auto;
}
#gdzie-kupic .top-normal-img .txt-container {
  font-size: 20px;
  color: #4e576d;
}
@media (min-width: 736px) {
  #gdzie-kupic .top-normal-img .container-fluid {
    padding-right: 0;
  }
  #gdzie-kupic .top-normal-img .container-fluid .row {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  #gdzie-kupic .sklepy-section .txt-container {
    padding-top: 0 !important;
  }
}
#gdzie-kupic .sklepy-section .txt-container h1 {
  margin-bottom: 70px;
}
#gdzie-kupic .sklepy-section .standard-container-left .row > div.txt-container {
  padding: 74px 56px 0px;
}
#gdzie-kupic .sklepy-section .sklepy-container .row {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 736px) {
  #gdzie-kupic .sklepy-section .sklepy-container .row {
    text-align: center;
  }
}
#gdzie-kupic .sklepy-section .sklepy-container .row img {
  max-width: 100%;
}
#gdzie-kupic .standard-container-left {
  height: auto;
  min-height: 0;
}
#gdzie-kupic .standard-container-left .row {
  height: auto;
}
@media (max-width: 1024px) {
  #gdzie-kupic .standard-container-left .row .txt-container {
    padding: 45px 55px;
  }
}
@media (max-width: 736px) {
  #gdzie-kupic .standard-container-left .row .txt-container {
    padding: 45px 30px;
    text-align: left;
  }
  #gdzie-kupic .standard-container-left .row .txt-container h1 {
    margin-bottom: 0;
  }
}
@media (max-width: 375px) {
  #gdzie-kupic .standard-container-left .row .txt-container {
    padding: 30px;
  }
}
#gdzie-kupic .standard-container-left .sklepy-container {
  padding-top: 74px;
}
@media (max-width: 1024px) {
  #gdzie-kupic .standard-container-left .sklepy-container {
    padding: 45px 0;
  }
}
@media (max-width: 991px) {
  #gdzie-kupic .standard-container-left .sklepy-container {
    padding: 0 55px 55px;
  }
}
@media (max-width: 736px) {
  #gdzie-kupic .standard-container-left .sklepy-container {
    padding: 0 30px 45px;
  }
}
#gdzie-kupic .standard-container-left .sklepy-container > .row {
  height: auto;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 991px) {
  #gdzie-kupic .standard-container-left .sklepy-container > .row {
    margin: 0;
  }
}
#gdzie-kupic .standard-container-left .sklepy-container .localization {
  color: #4e576d;
  height: auto;
}
@media (max-width: 991px) {
  #gdzie-kupic .standard-container-left .sklepy-container .localization {
    padding: 0 40px 0 0;
  }
}
#gdzie-kupic .standard-container-left .sklepy-container .localization h3 {
  font-size: 16px;
  font-weight: 900;
  color: #abb2bb;
  margin: 0;
  height: 17px;
  display: inline-block;
}
@media (max-width: 991px) {
  #gdzie-kupic .standard-container-left .sklepy-container .localization h3 {
    padding-left: 40px;
    line-height: 2em;
    padding: 0;
  }
}
#gdzie-kupic .standard-container-left .sklepy-container .localization .row {
  display: flex;
  flex-wrap: wrap;
}
#gdzie-kupic .standard-container-left .sklepy-container .localization .sklep {
  font-size: 14px;
  margin-bottom: 60px;
}
@media (max-width: 991px) {
  #gdzie-kupic .standard-container-left .sklepy-container .localization .sklep {
    padding: 0 40px;
    margin-bottom: 0;
    padding: 0 0 50px;
  }
}
#gdzie-kupic .standard-container-left .sklepy-container .localization .sklep strong {
  margin-top: 20px;
  margin-bottom: 15px;
  display: block;
}
#gdzie-kupic .standard-container-left .sklepy-container .localization .sklep p {
  margin-bottom: 20px;
}
#gdzie-kupic .standard-container-left .sklepy-container .localization .sklep a {
  text-decoration: underline;
  color: #4e576d;
  font-style: italic;
}
@media (min-width: 1200px) {
  #gdzie-kupic .standard-container-left .sklepy-container .col-lg-3 {
    width: 24.5%;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  #gdzie-kupic .standard-container-left .sklepy-container .col-lg-3 {
    width: 33%;
  }
}
@media (max-width: 991px) and (min-width: 650px) {
  #gdzie-kupic .standard-container-left .sklepy-container .col-lg-3 {
    width: 49%;
  }
}
@media (min-width: 1200px) {
  #gdzie-kupic .standard-container-left .sklepy-container .col-md-3 {
    width: 24.5%;
  }
}
@media (min-width: 768px) {
  #gdzie-kupic .standard-container-left .sklepy-container .col-sm-3 {
    width: 24.5%;
  }
}
@media (max-width: 767px) {
  #gdzie-kupic .standard-container-left .sklepy-container .col-xs-6 {
    width: 49%;
  }
}
#gdzie-kupic #wyszukaj {
  margin-top: 50px;
}
#gdzie-kupic #wyszukaj input {
  border: 2px solid #4e576d;
  border-radius: 10px;
  padding: 20px 15px;
  outline: none;
  font-size: 16px;
  width: 100%;
}
@media (max-width: 991px) and (min-width: 426px) {
  #gdzie-kupic #wyszukaj input {
    width: calc(100% - 121px);
  }
}
#gdzie-kupic #wyszukaj a {
  border-radius: 10px;
  background-color: #4e576d;
  color: #fff;
  font-weight: 900;
  font-size: 16px;
  padding: 12px 35px;
  margin-top: 10px;
  display: inline-block;
}
#gdzie-kupic #search-result {
  margin-top: 20px;
}
#gdzie-kupic #search-result .results {
  height: 136px;
  overflow-y: auto;
}
#gdzie-kupic #search-result .results .sklep {
  border-bottom: 1px solid #4e576d;
  margin: 20px;
}
@media (max-width: 736px) {
  #gdzie-kupic #search-result .results .sklep {
    margin: 0;
  }
}
#gdzie-kupic #search-result .results .sklep div {
  margin-bottom: 20px;
}
#gdzie-kupic #search-result .results .sklep .nazwa {
  font-weight: bold;
}
#gdzie-kupic #geolocalization {
  font-size: 11px;
  margin-top: 20px;
}
#gdzie-kupic #geolocalization a {
  color: #4e576d;
}
#gdzie-kupic #geolocalization a span {
  text-decoration: underline;
}
#gdzie-kupic #geolocalization a img {
  margin-left: 10px;
}
#gdzie-kupic .infowindow {
  border-radius: 5px;
}
#kontakt .top-normal-img#kontakt-mapa-wrapper {
  height: auto;
}
@media (min-width: 736px) {
  #kontakt .top-normal-img .container-fluid {
    padding-right: 0;
  }
  #kontakt .top-normal-img .container-fluid .row {
    margin-right: 0;
  }
}
#kontakt .top-normal-img a {
  text-decoration: none;
  color: #4e576d;
}
#kontakt .top-normal-img .txt-container {
  color: #4e576d;
  font-size: 20px;
}
@media (max-width: 1024px) {
  #kontakt .top-normal-img .txt-container {
    padding: 45px 55px;
  }
}
@media (max-width: 736px) {
  #kontakt .top-normal-img .txt-container {
    padding: 45px 30px;
  }
}
@media (max-width: 414px) {
  #kontakt .top-normal-img .txt-container {
    padding: 30px;
  }
}
#kontakt .top-normal-img .txt-container h1 {
  color: #abb2bb;
}
#kontakt .top-normal-img .txt-container .nazwa-firmy {
  display: block;
  font-weight: 900;
  margin-bottom: 24px;
}
#kontakt .top-normal-img .e-contact {
  margin-top: 24px;
}
#kontakt .top-normal-img .e-contact span {
  display: block;
  clear: both;
}
#kontakt .top-normal-img .additional-data {
  margin-top: 24px;
}
@media (max-width: 736px) {
  #kontakt .top-normal-img .additional-data table {
    margin: 0 auto;
  }
}
#kontakt .top-normal-img .additional-data tr td:nth-child(2) {
  padding-left: 10px;
}
#kontakt .standard-container-left {
  clear: both;
  height: auto;
  min-height: 0;
  padding-bottom: 30px;
}
#kontakt .standard-container-left .row {
  height: auto;
}
#kontakt .standard-container-left .row .txt-container {
  padding: 92px 56px;
}
@media (max-width: 1024px) {
  #kontakt .standard-container-left .row .txt-container {
    padding: 45px 55px 0;
  }
}
@media (max-width: 736px) {
  #kontakt .standard-container-left .row .txt-container {
    padding: 45px 30px 0;
  }
}
#kontakt .standard-container-left .row .txt-container h1 {
  margin: 0;
}
#kontakt .standard-container-left .row .contact-form {
  padding: 92px 0;
}
@media (max-width: 1024px) {
  #kontakt .standard-container-left .row .contact-form {
    padding: 50px 55px 0;
  }
}
@media (max-width: 736px) {
  #kontakt .standard-container-left .row .contact-form {
    padding: 50px 30px 0;
  }
}
#kontakt .standard-container-left .row .contact-form input:nth-child(2) {
  margin-top: 18px;
}
#kontakt .standard-container-left .row .contact-form .contact-info {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  #kontakt .standard-container-left .row .contact-form .contact-info {
    margin-bottom: 110px;
  }
}
@media (max-width: 736px) {
  #kontakt .standard-container-left .row .contact-form .contact-info .image {
    padding-bottom: 30px;
  }
}
#kontakt .standard-container-left .row .contact-form .contact-info .image img {
  max-width: 100%;
  max-height: 100%;
}
#kontakt .standard-container-left .row .contact-form .contact-info .desc,
#kontakt .standard-container-left .row .contact-form .contact-info .contact-1,
#kontakt .standard-container-left .row .contact-form .contact-info .contact-2 {
  margin-bottom: 30px;
}
#kontakt .standard-container-left .row .contact-form textarea {
  height: 94px;
}
@media (max-width: 767px) {
  #kontakt .standard-container-left .row .contact-form textarea {
    margin-top: 18px;
  }
}
#kontakt .standard-container-left .row .contact-form button {
  margin-top: 37px;
  margin-left: 15px;
}
@media (max-width: 1024px) {
  #kontakt .centrum-prasowe > .container-fluid .row {
    margin-right: -15px;
  }
}
#kontakt .centrum-prasowe > .container-fluid .row .logos .row > div {
  border-radius: 5px;
  margin: 0 auto;
}
#kontakt .centrum-prasowe > .container-fluid .row .logos .row > div > .row > div {
  padding: 45px calc((50% - 197px) / 2);
}
@media (max-width: 767px) {
  #kontakt .centrum-prasowe > .container-fluid .row .logos .row > div > .row > div {
    padding: 45px 30px;
  }
}
#kontakt .centrum-prasowe > .container-fluid .row .logos .row > div > .row > div img {
  width: 197px;
}
#kontakt .centrum-prasowe > .container-fluid .row .logos .row > div > .row > div > div {
  display: flex;
  flex-wrap: nowrap;
}
@media (max-width: 767px) {
  #kontakt .centrum-prasowe > .container-fluid .row .logos .row > div > .row > div > div {
    display: block;
  }
}
#kontakt .centrum-prasowe > .container-fluid .row .logos .row > div > .row > div > div .download {
  display: inline-block;
  padding: 5px 7px 7px;
  border: 2px solid #e4e4e4;
  border-radius: 10px;
  color: #4e576d;
  font-weight: bold;
  font-size: 16px;
  margin-right: 28px;
}
#kontakt .centrum-prasowe > .container-fluid .row .logos .row > div > .row > div > div .download:last-child {
  margin-right: 0;
}
#kontakt .centrum-prasowe > .container-fluid .row .logos .row > div.dark {
  background: #4e576d;
}
#kontakt .centrum-prasowe > .container-fluid .row .logos .row > div.dark > .row > div > div .download {
  border-color: #fff;
  color: #fff;
}
#filary .top-normal-img .txt-container {
  color: #4e576d;
  font-size: 20px;
}
@media (max-width: 736px) {
  #filary .top-normal-img .txt-container h1 p {
    font-size: 40px;
  }
}
@media (max-width: 736px) {
  #filary .top-normal-img .txt-container p {
    font-size: 20px;
  }
}
@media (max-width: 991px) {
  #filary .top-normal-img {
    height: auto;
  }
  #filary .top-normal-img .img-container {
    display: none !important;
  }
}
#filary .half-logo {
  z-index: 999999;
}
@media (max-width: 991px) {
  #filary .half-logo {
    display: none !important;
  }
}
#filary .img-container {
  z-index: 9999;
}
@media (max-width: 991px) {
  #filary .img-container {
    display: block !important;
    height: 350px;
  }
}
#filary .txt-container {
  z-index: 9999;
  background-color: #fff;
}
#filary .txt-container h1 {
  margin-bottom: 50px;
}
#filary .txt-container h1 p {
  font-size: 46px;
}
@media (max-width: 736px) {
  #filary .txt-container h1 p {
    font-size: 39px;
  }
}
@media (max-width: 1550px) {
  #filary .txt-container {
    padding-top: 30px;
  }
  #filary .txt-container h1 {
    margin-bottom: 40px;
  }
  #filary .txt-container p {
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  #filary .txt-container {
    display: block;
    min-height: 450px;
    height: auto;
  }
  #filary .txt-container .btn-zaffiro {
    position: absolute;
    bottom: 30px;
    left: 70px;
  }
}
@media (max-width: 1024px) {
  #filary .txt-container {
    padding: 45px 55px;
  }
  #filary .txt-container .btn-zaffiro {
    bottom: 30px;
    left: 55px;
  }
}
@media (max-width: 736px) {
  #filary .txt-container {
    padding: 45px 30px;
  }
  #filary .txt-container .btn-zaffiro {
    bottom: 30px;
    left: 30px;
  }
}
#filary .txt-container p {
  font-size: 19px;
}
@media (min-width: 992px) and (max-width: 1300px) {
  #filary .txt-container p {
    font-size: 18px;
  }
}
#filary .standard-container {
  width: 100%;
}
@media (max-width: 991px) {
  #filary .standard-container {
    height: auto;
  }
}
#filary .standard-container.left .half-logo {
  left: calc(75% - 25px);
  top: calc(50% - 20px);
}
@media (max-width: 1199px) {
  #filary .standard-container.left .half-logo {
    left: calc(66.69% - 25px);
  }
}
#filary .standard-container.right .img-container {
  float: right;
}
#filary .standard-container.right .half-logo {
  left: calc(25% - 24px);
  top: calc(50% - 20px);
}
@media (max-width: 1199px) {
  #filary .standard-container.right .half-logo {
    left: calc(33.46% - 25px);
  }
}
#filary .standard-container .rozwin {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
#filary .standard-container .rozwin .btn-zaffiro {
  position: absolute;
  bottom: 30px;
}
@media (max-width: 375px) {
  #filary .standard-container .rozwin .btn-zaffiro {
    left: 30px;
  }
}
@media (max-width: 991px) {
  #filary .standard-container .rozwin {
    top: 350px;
  }
}
#filary .standard-container .rozwin .content {
  padding: 90px 150px;
}
@media (max-width: 991px) {
  #filary .standard-container .rozwin .content {
    padding: 0;
  }
}
#filary .standard-container .rozwin .close {
  display: block;
  position: absolute;
  top: 80px;
  right: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 20px;
  width: 20px;
  opacity: 1;
  cursor: pointer;
}
@media (max-width: 991px) {
  #filary .standard-container .rozwin .close {
    z-index: 100;
    top: 20px;
    right: 30px;
  }
}
@media (max-width: 991px) {
  #filary .standard-container .rozwin .content {
    display: block;
  }
}
@media (max-width: 991px) {
  #filary .standard-container .rozwin .content .content-item {
    position: absolute;
    height: 350px;
    background-color: #fff;
    display: block;
    width: 100%;
    padding: 20px 53px 67px;
    white-space: normal;
    left: 100%;
  }
}
@media (max-width: 991px) {
  #filary .standard-container .rozwin .content .content-item {
    padding: 45px 55px;
  }
}
@media (max-width: 736px) {
  #filary .standard-container .rozwin .content .content-item {
    padding: 45px 30px;
  }
}
#filary .standard-container .rozwin .content .content-item h1 {
  color: #babec7;
  font-size: 39px;
  font-weight: 700;
  margin-top: 70px;
}
@media (max-width: 1200px) {
  #filary .standard-container .rozwin .content .content-item h1 {
    font-size: 35px;
  }
}
@media (max-width: 991px) {
  #filary .standard-container .rozwin .content .content-item h1 {
    margin-top: 20px !important;
  }
}
@media (max-width: 736px) {
  #filary .standard-container .rozwin .content .content-item h1 {
    margin-top: 0 !important;
  }
}
#filary .standard-container .rozwin .content .content-item:first-child h1 {
  margin-top: 0;
  margin-bottom: 10px;
}
@media (max-width: 991px) {
  #filary .standard-container .rozwin .content .content-item:first-child {
    left: 0;
  }
}
#filary .standard-container .rozwin .content .content-item h2 {
  color: #babec7;
  font-size: 41px;
  font-weight: 700;
}
@media (max-width: 1200px) {
  #filary .standard-container .rozwin .content .content-item h2 {
    font-size: 35px;
  }
}
@media (max-width: 736px) {
  #filary .standard-container .rozwin .content .content-item h2 {
    margin-top: 0 !important;
  }
}
#filary .standard-container .rozwin .content .content-item p {
  color: #4e576d;
  margin-bottom: 50px;
  color: #4e576d;
  font-size: 21px;
}
@media (max-width: 1550px) {
  #filary .standard-container .rozwin .content .content-item p {
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  #filary .standard-container .rozwin .content .content-item p {
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) {
  #filary .standard-container .rozwin .next {
    display: none !important;
  }
}
#filary .standard-container .rozwin .zwin-left {
  left: 78% !important;
  bottom: 71px;
}
@media (max-width: 991px) {
  #filary .standard-container .rozwin .zwin-left {
    display: none !important;
  }
}
#filary .standard-container .rozwin .zwin-right {
  left: 3%;
  bottom: 71px;
}
@media (max-width: 991px) {
  #filary .standard-container .rozwin .zwin-right {
    display: none !important;
  }
}
#o-nas .top-normal-img .txt-container {
  color: #4e576d;
  font-size: 20px;
}
@media (min-width: 1700px) {
  #o-nas .top-normal-img .txt-container > p {
    font-size: 24px;
  }
}
@media (max-width: 991px) {
  #o-nas .top-normal-img {
    height: auto;
  }
}
@media (max-width: 991px) {
  #o-nas .standard-container {
    height: auto;
  }
  #o-nas .standard-container .row {
    height: auto;
  }
}
#o-nas .standard-container .txt-container h1 {
  margin-bottom: 45%;
}
@media (max-width: 991px) {
  #o-nas .standard-container .txt-container h1 {
    margin-bottom: 45px;
  }
}
#o-nas .standard-container .txt-container p {
  font-size: 20px;
}
@media (max-width: 991px) {
  #o-nas .img-container {
    height: 450px;
  }
}
#o-nas #design,
#o-nas .design {
  background-color: #f4faff;
}
#o-nas #design:nth-child(2n+1 ),
#o-nas .design:nth-child(2n+1 ) {
  background: #fff;
}
@media (max-width: 1024px) {
  #o-nas #design .container-fluid,
  #o-nas .design .container-fluid {
    padding: 0px;
  }
}
#o-nas #design .container-fluid .standard-container,
#o-nas .design .container-fluid .standard-container {
  width: 100%;
}
#o-nas #design .container-fluid .standard-container > .row,
#o-nas .design .container-fluid .standard-container > .row {
  width: 80%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  #o-nas #design .container-fluid .standard-container > .row,
  #o-nas .design .container-fluid .standard-container > .row {
    width: 100%;
  }
}
@media (min-width: 992px) {
  #o-nas #design .container-fluid .standard-container .txt-container > div,
  #o-nas .design .container-fluid .standard-container .txt-container > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
#o-nas #design .container-fluid .standard-container .txt-container h1,
#o-nas .design .container-fluid .standard-container .txt-container h1 {
  margin-top: 0;
  margin-bottom: 65px;
}
@media (max-width: 991px) {
  #o-nas #design .container-fluid .standard-container .txt-container h1,
  #o-nas .design .container-fluid .standard-container .txt-container h1 {
    margin-top: 40px;
  }
}
@media (max-width: 736px) {
  #o-nas #design .container-fluid .standard-container .txt-container h1,
  #o-nas .design .container-fluid .standard-container .txt-container h1 {
    font-size: 39px;
    margin: 0 0 45px 0;
  }
}
#o-nas #design .container-fluid .standard-container .img-container,
#o-nas .design .container-fluid .standard-container .img-container {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
}
@media (max-width: 991px) {
  #o-nas #design .container-fluid .standard-container .img-container,
  #o-nas .design .container-fluid .standard-container .img-container {
    height: 400px;
  }
}
#o-nas .design .container-fluid .standard-container .txt-container {
  float: right;
}
#o-nas .design .container-fluid .standard-container .txt-container:nth-child(2n ) {
  float: none;
}
#o-nas .design .container-fluid .standard-container .img-container {
  float: left;
}
#o-nas .design .container-fluid .standard-container .img-container:nth-child(2n ) {
  float: none;
}
@media (max-width: 991px) {
  #o-nas .design .container-fluid .standard-container .img-container,
  #o-nas .design .container-fluid .standard-container .txt-container {
    float: none;
  }
}
#o-nas #nagrody {
  background-color: #fff;
}
#o-nas #nagrody .standard-container {
  width: 100%;
}
#o-nas #nagrody .standard-container > .row {
  width: 80%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  #o-nas #nagrody .standard-container > .row {
    width: 100%;
  }
}
#o-nas #nagrody .standard-container > .row .txt-container > div {
  margin-top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1024px) {
  #o-nas #nagrody .standard-container > .row .txt-container > div {
    margin-top: 0;
    transform: none;
  }
}
#o-nas #nagrody .standard-container > .row .txt-container > div h1 {
  margin-bottom: 40px;
}
#o-nas #nagrody .standard-container .nagrody-cont {
  margin-top: 120px;
  height: auto;
}
@media (min-width: 1024px) {
  #o-nas #nagrody .standard-container .nagrody-cont {
    float: left;
    padding-left: 55px;
  }
}
@media (max-width: 991px) {
  #o-nas #nagrody .standard-container .nagrody-cont {
    margin-top: 60px;
  }
}
#o-nas #nagrody .standard-container .nagrody-cont .nagroda-item img {
  display: block;
  margin: 0 auto 45px;
  max-width: 70%;
}
@media (min-width: 992px) {
  #o-nas #nagrody .standard-container .nagrody-cont .nagroda-item img {
    max-width: 130px;
  }
}
@media (min-width: 992px) {
  #o-nas #nagrody .standard-container .nagrody-cont .nagroda-item:nth-child(3n - 2 ) img {
    margin-right: 30px;
  }
}
@media (min-width: 992px) {
  #o-nas #nagrody .standard-container .nagrody-cont .nagroda-item:nth-child(3n - 1 ) img {
    margin: 0 auto;
  }
}
@media (min-width: 678px) {
  #o-nas #nagrody .standard-container .txt-container {
    float: right;
  }
}
#o-nas #nagrody .standard-container .img-container.strzalki {
  position: relative;
}
#o-nas #nagrody .standard-container .img-container.strzalki .lewo,
#o-nas #nagrody .standard-container .img-container.strzalki .prawo {
  position: absolute;
  top: 25%;
  cursor: pointer;
}
#o-nas #nagrody .standard-container .img-container.strzalki .lewo {
  left: 30px;
}
@media (max-width: 550px) {
  #o-nas #nagrody .standard-container .img-container.strzalki .lewo {
    left: 10px;
  }
}
#o-nas #nagrody .standard-container .img-container.strzalki .prawo {
  right: 30px;
}
@media (max-width: 550px) {
  #o-nas #nagrody .standard-container .img-container.strzalki .prawo {
    right: 10px;
  }
}
#o-nas #nagrody .standard-container .img-container img {
  max-width: 80%;
  display: block;
  margin: 0 auto;
  margin-top: 145px;
}
#o-nas #map-bottom {
  background-color: #f4faff;
  padding: 140px 0;
  background-repeat: no-repeat;
  background-position: right center;
}
@media (max-width: 992px) {
  #o-nas #map-bottom {
    background-image: none !important;
  }
}
@media (min-width: 992px) {
  #o-nas #map-bottom .map-img {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  #o-nas #map-bottom {
    padding: 30px 0;
  }
}
@media (max-width: 1024px) {
  #o-nas #map-bottom .container-fluid {
    padding: 0px;
  }
}
#o-nas #map-bottom .container-fluid .standard-container {
  width: 100%;
}
#o-nas #map-bottom .container-fluid .standard-container > .row {
  width: 80%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  #o-nas #map-bottom .container-fluid .standard-container > .row {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  #o-nas #map-bottom .container-fluid .container {
    padding: 0px;
  }
}
#o-nas #map-bottom .container-fluid img {
  max-width: 100%;
  display: block;
  margin: 0 0 30px;
}
#o-nas #map-bottom .container-fluid img.map-img {
  margin: 0 -30px;
  width: calc(100% + 60px);
  max-width: initial;
}
@media (max-width: 1200px) {
  #o-nas #map-bottom {
    background-size: contain;
  }
}
#produkt {
  color: #4e576d;
}
#produkt p {
  font-size: 24px;
}
#produkt .standard-container-left,
#produkt .standard-container-right,
#produkt .standard-container {
  clear: both;
  overflow: hidden;
  height: auto;
}
#produkt .standard-container-left.columns-p > .container-fluid > .row,
#produkt .standard-container-right.columns-p > .container-fluid > .row,
#produkt .standard-container.columns-p > .container-fluid > .row {
  height: auto;
  margin-right: -15px;
}
#produkt .top-normal-img {
  height: 80vh;
  background-color: #f5fafe;
}
@media (max-width: 1600px) {
  #produkt .top-normal-img {
    height: auto;
  }
}
#produkt .top-normal-img .txt-container {
  height: 80vh;
  color: #4e576d;
}
@media (max-width: 991px) {
  #produkt .top-normal-img .txt-container {
    height: 50vh;
    padding: 45px 53px;
  }
}
@media (max-width: 768px) {
  #produkt .top-normal-img .txt-container {
    padding: 45px 55px;
  }
}
@media (max-width: 736px) {
  #produkt .top-normal-img .txt-container {
    padding: 45px 30px;
  }
}
@media (max-width: 435px) {
  #produkt .top-normal-img .txt-container {
    height: auto;
  }
  #produkt .top-normal-img .txt-container a.button-group {
    position: relative;
    left: 0;
  }
}
@media (max-width: 413px) {
  #produkt .top-normal-img .txt-container {
    padding: 50px 30px;
  }
}
@media (max-height: 414px) {
  #produkt .top-normal-img .txt-container {
    height: 120vh;
  }
}
#produkt .top-normal-img .txt-container p {
  font-size: 20px;
}
#produkt .top-normal-img .txt-container h1,
#produkt .top-normal-img .txt-container h1 p {
  font-size: 40px;
}
#produkt .top-normal-img .txt-container h1 {
  margin-bottom: 30px;
}
#produkt .top-normal-img .txt-container a.button-group {
  position: absolute;
  bottom: 50px;
  left: 40px;
}
@media (max-width: 435px) {
  #produkt .top-normal-img .txt-container a.button-group {
    bottom: 0;
  }
}
#produkt .top-normal-img .txt-container img.pikto {
  width: 110px;
  margin-bottom: 30px;
}
#produkt .top-normal-img .img-container {
  height: 80vh;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
}
@media (max-width: 991px) {
  #produkt .top-normal-img .img-container {
    height: 450px;
  }
}
#produkt .standard-container-left .txt-container {
  float: left;
  padding-left: 250px;
}
#produkt .standard-container-left .img-container {
  float: right;
}
#produkt .standard-container-right .txt-container {
  padding-right: 250px;
}
#produkt .standard-container-left.columns-p .txt-container,
#produkt .standard-container-right.columns-p .txt-container {
  padding: 74px 120px 0;
}
@media (max-width: 667px) {
  #produkt .standard-container-left.columns-p .txt-container,
  #produkt .standard-container-right.columns-p .txt-container {
    padding: 10px 15px;
    margin-bottom: 70px;
  }
}
@media (max-width: 435px) {
  #produkt .standard-container-left.columns-p .txt-container,
  #produkt .standard-container-right.columns-p .txt-container {
    padding: 50px 30px;
  }
}
@media (min-width: 991px) and (max-width: 1250px) {
  #produkt .standard-container-left.columns-p .txt-container,
  #produkt .standard-container-right.columns-p .txt-container {
    padding: 0 54px;
  }
}
@media (min-width: 769px) and (max-width: 990px) {
  #produkt .standard-container-left.columns-p .txt-container,
  #produkt .standard-container-right.columns-p .txt-container {
    padding: 0 54px 60px;
  }
}
@media (min-width: 737px) and (max-width: 768px) {
  #produkt .standard-container-left.columns-p .txt-container,
  #produkt .standard-container-right.columns-p .txt-container {
    padding: 0 55px 60px;
  }
}
@media (min-width: 436px) and (max-width: 736px) {
  #produkt .standard-container-left.columns-p .txt-container,
  #produkt .standard-container-right.columns-p .txt-container {
    padding: 0 30px 60px;
  }
}
#produkt .standard-container-left.columns-p .txt-container h1,
#produkt .standard-container-right.columns-p .txt-container h1 {
  margin-top: 65px;
  margin-bottom: 65px;
  color: #4e576d;
  font-size: 61px;
}
@media (max-width: 1700px) {
  #produkt .standard-container-left.columns-p .txt-container h1,
  #produkt .standard-container-right.columns-p .txt-container h1 {
    font-size: 46px;
  }
}
@media (min-width: 1100px) and (max-width: 1250px) {
  #produkt .standard-container-left.columns-p .txt-container h1,
  #produkt .standard-container-right.columns-p .txt-container h1 {
    margin-top: 90px;
  }
}
@media (min-width: 991px) and (max-width: 1099px) {
  #produkt .standard-container-left.columns-p .txt-container h1,
  #produkt .standard-container-right.columns-p .txt-container h1 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
@media (max-width: 736px) {
  #produkt .standard-container-left.columns-p .txt-container h1,
  #produkt .standard-container-right.columns-p .txt-container h1 {
    margin: 45px 0;
  }
}
@media (max-width: 435px) {
  #produkt .standard-container-left.columns-p .txt-container h1,
  #produkt .standard-container-right.columns-p .txt-container h1 {
    margin: 0 0 48px;
  }
}
#produkt .standard-container-left.columns-p .txt-container h1 p,
#produkt .standard-container-right.columns-p .txt-container h1 p {
  font-size: 61px;
}
@media (max-width: 1700px) {
  #produkt .standard-container-left.columns-p .txt-container h1 p,
  #produkt .standard-container-right.columns-p .txt-container h1 p {
    font-size: 46px;
  }
}
@media (max-width: 676px) {
  #produkt .standard-container-left.columns-p .txt-container h1 p,
  #produkt .standard-container-right.columns-p .txt-container h1 p {
    font-size: 39px;
  }
}
#produkt .standard-container-left.columns-p .txt-container p,
#produkt .standard-container-right.columns-p .txt-container p,
#produkt .standard-container-left.columns-p .txt-container ul,
#produkt .standard-container-right.columns-p .txt-container ul,
#produkt .standard-container-left.columns-p .txt-container li,
#produkt .standard-container-right.columns-p .txt-container li {
  font-size: 22px;
}
@media (max-width: 1700px) {
  #produkt .standard-container-left.columns-p .txt-container p,
  #produkt .standard-container-right.columns-p .txt-container p,
  #produkt .standard-container-left.columns-p .txt-container ul,
  #produkt .standard-container-right.columns-p .txt-container ul,
  #produkt .standard-container-left.columns-p .txt-container li,
  #produkt .standard-container-right.columns-p .txt-container li {
    font-size: 20px;
  }
}
#produkt .standard-container-left.columns-p .img-container,
#produkt .standard-container-right.columns-p .img-container {
  background-size: cover;
  background-repeat: no-repeat;
  height: 700px;
}
@media (max-width: 676px) {
  #produkt .standard-container-left.columns-p .img-container,
  #produkt .standard-container-right.columns-p .img-container {
    height: 350px;
    background-size: cover;
  }
}
#produkt .standard-container h1 {
  color: #4e576d;
}
@media (max-width: 736px) {
  #produkt .standard-container h1 {
    margin-top: 45px !important;
    margin-bottom: 30px;
  }
}
@media (max-width: 435px) {
  #produkt .standard-container h1 {
    margin-top: 0 !important;
  }
}
#produkt .standard-container h1 p {
  font-size: 48px;
}
@media (max-width: 1200px) {
  #produkt .standard-container h1 p {
    font-size: 43px;
  }
}
@media (max-width: 676px) {
  #produkt .standard-container h1 p {
    font-size: 39px;
  }
}
#produkt .standard-container p {
  font-size: 22px;
  color: #4e576d;
  padding-bottom: 20px;
}
@media (max-width: 1700px) {
  #produkt .standard-container p {
    font-size: 20px;
  }
}
#produkt .standard-container .btn-zaffiro {
  position: relative;
  bottom: 0;
  left: 0;
  line-height: 14px;
}
#produkt .standard-container.gray h1 {
  margin-top: 118px;
}
#produkt .standard-container.grupa {
  padding: 0;
  height: 700px;
}
@media (min-width: 992px) {
  #produkt .standard-container.grupa > .row {
    padding: 0 54px 0 41px;
  }
}
@media (min-width: 1150px) {
  #produkt .standard-container.grupa > .row {
    padding: 0 60px 0 120px;
  }
}
@media (max-width: 991px) {
  #produkt .standard-container.grupa {
    padding-top: 0;
    height: auto;
  }
  #produkt .standard-container.grupa .standard-container h1 {
    margin-top: 35px;
  }
}
#produkt .standard-container.grupa .text {
  padding-right: 135px;
  margin-top: 350px;
  transform: translateY(-50%);
}
@media (max-width: 991px) {
  #produkt .standard-container.grupa .text {
    padding: 60px 120px 30px;
    margin-top: 0;
    transform: none;
  }
}
@media (min-width: 737px) and (max-width: 991px) {
  #produkt .standard-container.grupa .text {
    padding: 60px 54px 30px;
  }
}
@media (min-width: 436px) and (max-width: 736px) {
  #produkt .standard-container.grupa .text {
    padding: 0 30px 30px;
  }
}
@media (max-width: 1150px) and (min-width: 992px) {
  #produkt .standard-container.grupa .text {
    padding-right: 35px;
  }
}
@media (max-width: 435px) {
  #produkt .standard-container.grupa .text {
    padding: 50px 30px;
  }
}
#produkt .standard-container.grupa .text ul,
#produkt .standard-container.grupa .text li,
#produkt .standard-container.grupa .text h2,
#produkt .standard-container.grupa .text h4 {
  font-size: 22px;
}
@media (max-width: 1700px) {
  #produkt .standard-container.grupa .text ul,
  #produkt .standard-container.grupa .text li,
  #produkt .standard-container.grupa .text h2,
  #produkt .standard-container.grupa .text h4 {
    font-size: 20px;
  }
}
#produkt .standard-container.grupa .text ul {
  padding-bottom: 30px;
}
@media (max-width: 425px) {
  #produkt .standard-container.grupa .text .serce {
    display: none;
  }
}
@media (max-width: 435px) {
  #produkt .standard-container.grupa .suwak-outer-container {
    padding: 0 55px 55px;
  }
}
#produkt .standard-container.grupa .suwak-outer-container .suwak-container {
  overflow-y: hidden;
  position: relative;
  width: 100%;
  padding: 0 10px;
}
@media (max-width: 991px) {
  #produkt .standard-container.grupa .suwak-outer-container .suwak-container {
    margin-left: 0;
  }
}
#produkt .standard-container.grupa .suwak-outer-container .suwak-container .left,
#produkt .standard-container.grupa .suwak-outer-container .suwak-container .right {
  top: calc(50% - 30px);
  position: absolute;
  cursor: pointer;
  z-index: 99;
  height: 60px;
}
#produkt .standard-container.grupa .suwak-outer-container .suwak-container .left {
  left: 0;
}
#produkt .standard-container.grupa .suwak-outer-container .suwak-container .right {
  right: 0;
}
#produkt .standard-container.grupa .suwak-outer-container .suwak-container .suwak-row {
  white-space: nowrap;
  overflow-x: auto;
  height: auto;
  margin-bottom: -20px;
}
@media (min-width: 551px) {
  #produkt .standard-container.grupa .suwak-outer-container .suwak-container .suwak-row {
    height: 720px;
  }
}
#produkt .standard-container.grupa .suwak-outer-container .suwak-container .suwak-row .suwak-two-items-container {
  display: inline-block;
  width: 49.5%;
}
@media (max-width: 550px) {
  #produkt .standard-container.grupa .suwak-outer-container .suwak-container .suwak-row .suwak-two-items-container {
    width: 100%;
  }
}
#produkt .standard-container.grupa .suwak-outer-container .suwak-container .suwak-row .suwak-two-items-container .example {
  height: 350px;
  margin-top: 0;
  text-align: center;
  width: 100%;
  cursor: pointer;
  position: relative;
  padding-top: 20px;
}
#produkt .standard-container.grupa .suwak-outer-container .suwak-container .suwak-row .suwak-two-items-container .example div {
  height: 250px;
}
#produkt .standard-container.grupa .suwak-outer-container .suwak-container .suwak-row .suwak-two-items-container .example:hover .description {
  display: block;
}
#produkt .standard-container.grupa .suwak-outer-container .suwak-container .suwak-row .suwak-two-items-container .example img {
  max-width: 100%;
  max-height: 100%;
}
#produkt .standard-container.grupa .suwak-outer-container .suwak-container .suwak-row .suwak-two-items-container .example .name p {
  padding: 0;
  margin: 0;
}
#produkt .standard-container.grupa .suwak-outer-container .suwak-container .suwak-row .suwak-two-items-container .example .description {
  background: rgba(242,247,251,0.98);
  padding: 61px 20px 0 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  white-space: normal;
}
#produkt .standard-container.grupa .suwak-outer-container .suwak-container .suwak-row .suwak-two-items-container .example .description .title {
  display: block;
  font-size: 16px;
  font-weight: bold;
}
#produkt .standard-container.grupa .suwak-outer-container .suwak-container .suwak-row .suwak-two-items-container .example .description .title span {
  display: block;
}
#produkt .standard-container.grupa .suwak-outer-container .suwak-container .suwak-row .suwak-two-items-container .example .description p {
  font-size: 16px;
}
#produkt .piktogramy {
  padding: 135px 0;
}
@media (max-width: 1600px) {
  #produkt .piktogramy {
    min-height: 0;
  }
}
@media (min-width: 1601px) {
  #produkt .piktogramy {
    padding: 270px 0;
  }
}
@media (max-width: 736px) {
  #produkt .piktogramy {
    padding: 35px 0;
  }
}
#produkt .piktogramy .flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#produkt .piktogramy .flex-container .piktogram {
  min-width: 120px;
  text-align: center;
  margin: 0 74px;
  width: calc(16% - 148px);
}
@media (min-width: 2000px) {
  #produkt .piktogramy .flex-container .piktogram {
    margin: 0 40px;
  }
}
@media (min-width: 1340px) and (max-width: 2000px) {
  #produkt .piktogramy .flex-container .piktogram {
    margin: 0 50px;
  }
}
@media (max-width: 1340px) and (min-width: 1072px) {
  #produkt .piktogramy .flex-container .piktogram {
    margin: 0 46px;
  }
}
@media (max-width: 750px) and (min-width: 600px) {
  #produkt .piktogramy .flex-container .piktogram {
    margin: 0 0px !important;
  }
}
#produkt .piktogramy .flex-container .piktogram img {
  width: 120px;
  height: 191px;
}
@media (max-width: 803px) {
  #produkt .piktogramy .flex-container .piktogram {
    margin: 0 15px;
    width: calc(20% - 148px);
  }
  #produkt .piktogramy .flex-container .piktogram img {
    width: 90px;
  }
}
#produkt .film {
  display: flex;
  justify-content: center;
  margin-bottom: 10%;
}
#produkt .film .flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
}
#produkt .film .flex-container iframe {
  width: 100%;
}
height: 100% > div {
  width: 100%;
  text-align: center;
}
height: 100% .title h3 {
  font-weight: bold;
  margin-top: 30px;
  font-size: 20px;
}
height: 100% .description p {
  margin-top: 10px;
  font-size: 20px;
}
height: 100% iframe {
  max-width: 100%;
}
.dodatkowe-rysunki {
  padding: 135px 0;
}
@media (max-width: 435px) {
  .dodatkowe-rysunki {
    padding: 0;
  }
}
@media (max-width: 1600px) {
  .dodatkowe-rysunki {
    min-height: 0;
  }
}
@media (min-width: 1601px) {
  .dodatkowe-rysunki {
    padding: 270px 0;
  }
}
@media (max-width: 413px) {
  .dodatkowe-rysunki .container {
    padding: 50px 30px;
  }
}
.dodatkowe-rysunki .container .rysunek {
  width: 30.333%;
  max-width: 100%;
  text-align: center;
  float: left;
  padding: 0 0 55px;
  margin-left: 2%;
}
.dodatkowe-rysunki .container .rysunek:last-child {
  margin-right: 0;
}
@media (max-width: 435px) {
  .dodatkowe-rysunki .container .rysunek {
    width: 100%;
  }
}
.dodatkowe-rysunki .container .rysunek img {
  width: 288px;
  max-width: 100%;
}
.dodatkowe-rysunki .container .rysunek .nazwa,
.dodatkowe-rysunki .container .rysunek .opis {
  display: block;
}
.dodatkowe-rysunki .container .rysunek .nazwa {
  font-weight: bold;
  margin-top: 30px;
}
.dodatkowe-rysunki .container .rysunek .opis {
  margin-top: 10px;
}
.powiazane-produkty {
  padding: 0;
}
@media (max-width: 435px) {
  .powiazane-produkty {
    margin: 0;
    padding: 0;
  }
}
@media (max-width: 1024px) {
  .powiazane-produkty #lista-produktow > div > .row > div {
    padding: 0;
    height: auto !important;
    padding: 50px 55px;
  }
  .powiazane-produkty #lista-produktow > div > .row > div .txt {
    padding: 0;
    height: auto;
  }
  .powiazane-produkty #lista-produktow > div > .row > div .txt h2 {
    height: auto;
    margin-bottom: 48px;
  }
  .powiazane-produkty #lista-produktow > div > .row > div .txt .btn-zaffiro {
    position: relative;
    margin-top: 20px;
    bottom: 0;
  }
  .powiazane-produkty #lista-produktow .produkt-col {
    overflow: hidden;
  }
  .powiazane-produkty #lista-produktow .produkt-col.start h1 {
    margin-bottom: 0 !important;
  }
  .powiazane-produkty #lista-produktow .img {
    display: none;
  }
}
@media (max-width: 736px) {
  .powiazane-produkty #lista-produktow > div > .row > div {
    padding: 50px 30px;
  }
}
@media (max-width: 650px) {
  .powiazane-produkty #lista-produktow > div > .row > div .txt h2 {
    margin-bottom: 14px;
  }
}
.bottom-imgs {
  padding-bottom: 100px;
  background-color: #f5fafe;
  margin-bottom: 0;
}
@media (max-width: 676px) {
  .bottom-imgs {
    margin-bottom: 0;
  }
}
.bottom-imgs .bottom-img-container {
  padding: 0;
}
.bottom-imgs .bottom-img-container img {
  max-width: 100%;
}
#produkty #lista-produktow .row > div,
#produkt #lista-produktow .row > div {
  margin-top: 0;
}
#produkty #lista-produktow .produkt-col,
#produkt #lista-produktow .produkt-col {
  height: 700px;
  padding: 0;
  background-color: #babec7;
  float: left;
  cursor: pointer;
  position: relative;
}
#produkty #lista-produktow .produkt-col:hover,
#produkt #lista-produktow .produkt-col:hover {
  background-color: #a4a8b0;
}
@media (min-width: 1700px) {
  #produkty #lista-produktow .produkt-col,
  #produkt #lista-produktow .produkt-col {
    height: 960px;
  }
  #produkty #lista-produktow .produkt-col .txt h2,
  #produkt #lista-produktow .produkt-col .txt h2 {
    margin-top: 10px;
  }
}
@media (min-width: 1400px) {
  #produkty #lista-produktow .produkt-col.start,
  #produkt #lista-produktow .produkt-col.start {
    width: 25%;
  }
  #produkty #lista-produktow .produkt-col.narrow-col,
  #produkt #lista-produktow .produkt-col.narrow-col {
    width: 25%;
  }
  #produkty #lista-produktow .produkt-col.narrow-col .wide-img,
  #produkt #lista-produktow .produkt-col.narrow-col .wide-img {
    display: none;
  }
  #produkty #lista-produktow .produkt-col.wide-col,
  #produkt #lista-produktow .produkt-col.wide-col {
    width: 50%;
  }
  #produkty #lista-produktow .produkt-col.wide-col .narrow-img,
  #produkt #lista-produktow .produkt-col.wide-col .narrow-img {
    display: none;
  }
}
@media (max-width: 1399px) and (min-width: 850px) {
  #produkty #lista-produktow .produkt-col.start,
  #produkt #lista-produktow .produkt-col.start {
    width: 33.333333% !important;
  }
  #produkty #lista-produktow .produkt-col.narrow-col,
  #produkt #lista-produktow .produkt-col.narrow-col {
    width: 33.333333%;
  }
  #produkty #lista-produktow .produkt-col.narrow-col .wide-img,
  #produkt #lista-produktow .produkt-col.narrow-col .wide-img {
    display: block;
  }
  #produkty #lista-produktow .produkt-col.wide-col,
  #produkt #lista-produktow .produkt-col.wide-col {
    width: 66.666666%;
  }
  #produkty #lista-produktow .produkt-col.wide-col .narrow-img,
  #produkt #lista-produktow .produkt-col.wide-col .narrow-img {
    display: none;
  }
  #produkty #lista-produktow .produkt-col:nth-child(2 ),
  #produkt #lista-produktow .produkt-col:nth-child(2 ) {
    width: 66.666666% !important;
  }
  #produkty #lista-produktow .produkt-col:nth-child(2 ) .narrow-img,
  #produkt #lista-produktow .produkt-col:nth-child(2 ) .narrow-img {
    display: none;
  }
  #produkty #lista-produktow .produkt-col:nth-child(4n - 3),
  #produkt #lista-produktow .produkt-col:nth-child(4n - 3) {
    width: 33.333333%;
  }
  #produkty #lista-produktow .produkt-col:nth-child(4n - 3) .wide-img,
  #produkt #lista-produktow .produkt-col:nth-child(4n - 3) .wide-img {
    display: none;
  }
  #produkty #lista-produktow .produkt-col:nth-child(4n - 3) .narrow-img,
  #produkt #lista-produktow .produkt-col:nth-child(4n - 3) .narrow-img {
    display: block;
  }
  #produkty #lista-produktow .produkt-col:nth-child(4n - 2 ),
  #produkt #lista-produktow .produkt-col:nth-child(4n - 2 ) {
    width: 66.666666%;
  }
  #produkty #lista-produktow .produkt-col:nth-child(4n - 2 ) .narrow-img,
  #produkt #lista-produktow .produkt-col:nth-child(4n - 2 ) .narrow-img {
    display: none;
  }
  #produkty #lista-produktow .produkt-col:nth-child(4n - 1 ),
  #produkt #lista-produktow .produkt-col:nth-child(4n - 1 ) {
    width: 66.666666%;
  }
  #produkty #lista-produktow .produkt-col:nth-child(4n - 1 ) .narrow-img,
  #produkt #lista-produktow .produkt-col:nth-child(4n - 1 ) .narrow-img {
    display: none;
  }
  #produkty #lista-produktow .produkt-col:nth-child(4n ),
  #produkt #lista-produktow .produkt-col:nth-child(4n ) {
    width: 33.333333%;
  }
  #produkty #lista-produktow .produkt-col:nth-child(4n ) .wide-img,
  #produkt #lista-produktow .produkt-col:nth-child(4n ) .wide-img {
    display: none;
  }
  #produkty #lista-produktow .produkt-col:nth-child(4n ) .narrow-img,
  #produkt #lista-produktow .produkt-col:nth-child(4n ) .narrow-img {
    display: block;
  }
}
@media (max-width: 849px) and (min-width: 710px) {
  #produkty #lista-produktow .produkt-col,
  #produkt #lista-produktow .produkt-col {
    height: 700px;
  }
}
@media (max-width: 709px) and (min-width: 550px) {
  #produkty #lista-produktow .produkt-col,
  #produkt #lista-produktow .produkt-col {
    height: 635px;
  }
}
@media (max-width: 849px) and (min-width: 550px) {
  #produkty #lista-produktow .produkt-col.start,
  #produkt #lista-produktow .produkt-col.start {
    width: 50%;
  }
  #produkty #lista-produktow .produkt-col:nth-child(3n - 2 ),
  #produkt #lista-produktow .produkt-col:nth-child(3n - 2 ) {
    width: 50%;
  }
  #produkty #lista-produktow .produkt-col:nth-child(3n - 2 ) .wide-img,
  #produkt #lista-produktow .produkt-col:nth-child(3n - 2 ) .wide-img {
    display: none;
  }
  #produkty #lista-produktow .produkt-col:nth-child(3n - 1 ),
  #produkt #lista-produktow .produkt-col:nth-child(3n - 1 ) {
    width: 50%;
  }
  #produkty #lista-produktow .produkt-col:nth-child(3n - 1 ) .wide-img,
  #produkt #lista-produktow .produkt-col:nth-child(3n - 1 ) .wide-img {
    display: none;
  }
  #produkty #lista-produktow .produkt-col:nth-child(3n ),
  #produkt #lista-produktow .produkt-col:nth-child(3n ) {
    width: 100%;
  }
  #produkty #lista-produktow .produkt-col:nth-child(3n ) .narrow-img,
  #produkt #lista-produktow .produkt-col:nth-child(3n ) .narrow-img {
    display: none;
  }
}
@media (max-width: 549px) {
  #produkty #lista-produktow .produkt-col,
  #produkt #lista-produktow .produkt-col {
    height: auto;
  }
  #produkty #lista-produktow .produkt-col.start,
  #produkt #lista-produktow .produkt-col.start {
    width: 100%;
  }
  #produkty #lista-produktow .produkt-col.start .wide-img,
  #produkt #lista-produktow .produkt-col.start .wide-img {
    display: none;
  }
  #produkty #lista-produktow .produkt-col.narrow-col,
  #produkt #lista-produktow .produkt-col.narrow-col {
    width: 100%;
  }
  #produkty #lista-produktow .produkt-col.narrow-col .wide-img,
  #produkt #lista-produktow .produkt-col.narrow-col .wide-img {
    display: none;
  }
  #produkty #lista-produktow .produkt-col.wide-col,
  #produkt #lista-produktow .produkt-col.wide-col {
    width: 100%;
  }
  #produkty #lista-produktow .produkt-col.wide-col .wide-img,
  #produkt #lista-produktow .produkt-col.wide-col .wide-img {
    display: none;
  }
}
#produkty #lista-produktow .produkt-col:nth-child(2n ),
#produkt #lista-produktow .produkt-col:nth-child(2n ) {
  background-color: #4e576d;
}
#produkty #lista-produktow .produkt-col:nth-child(2n ):hover,
#produkt #lista-produktow .produkt-col:nth-child(2n ):hover {
  background-color: #3d4557;
}
#produkty #lista-produktow .produkt-col:nth-child(2n ) .txt h2,
#produkt #lista-produktow .produkt-col:nth-child(2n ) .txt h2,
#produkty #lista-produktow .produkt-col:nth-child(2n ) .txt h2 p,
#produkt #lista-produktow .produkt-col:nth-child(2n ) .txt h2 p,
#produkty #lista-produktow .produkt-col:nth-child(2n ) .txt h2 p span,
#produkt #lista-produktow .produkt-col:nth-child(2n ) .txt h2 p span {
  color: #babec7 !important;
}
#produkty #lista-produktow .produkt-col.filary,
#produkt #lista-produktow .produkt-col.filary {
  background-color: #fff;
}
#produkty #lista-produktow .produkt-col.start,
#produkt #lista-produktow .produkt-col.start {
  background-color: #fff;
  padding: 109px 55px;
}
@media (max-width: 1024px) {
  #produkty #lista-produktow .produkt-col.start,
  #produkt #lista-produktow .produkt-col.start {
    padding: 45px 55px;
  }
}
@media (max-width: 736px) {
  #produkty #lista-produktow .produkt-col.start,
  #produkt #lista-produktow .produkt-col.start {
    padding: 45px 30px;
  }
}
#produkty #lista-produktow .produkt-col.start h1,
#produkt #lista-produktow .produkt-col.start h1,
#produkty #lista-produktow .produkt-col.start h1 p,
#produkt #lista-produktow .produkt-col.start h1 p {
  font-size: 52px !important;
  color: #abb2bb;
  font-weight: 900;
  margin-bottom: 66px;
  margin-top: 0;
}
@media (max-width: 999px) {
  #produkty #lista-produktow .produkt-col.start h1,
  #produkt #lista-produktow .produkt-col.start h1,
  #produkty #lista-produktow .produkt-col.start h1 p,
  #produkt #lista-produktow .produkt-col.start h1 p {
    font-size: 38px !important;
  }
}
#produkty #lista-produktow .produkt-col.start h1 span,
#produkt #lista-produktow .produkt-col.start h1 span,
#produkty #lista-produktow .produkt-col.start h1 p span,
#produkt #lista-produktow .produkt-col.start h1 p span {
  color: #4e576d;
}
#produkty #lista-produktow .produkt-col.start p,
#produkt #lista-produktow .produkt-col.start p {
  font-size: 20px;
  color: #4e576d;
}
#produkty #lista-produktow .produkt-col .img,
#produkt #lista-produktow .produkt-col .img {
  position: relative;
}
#produkty #lista-produktow .produkt-col .img img,
#produkt #lista-produktow .produkt-col .img img {
  width: 100%;
  display: block;
  margin: 0 auto;
}
#produkty #lista-produktow .produkt-col .img .pikto-blend,
#produkt #lista-produktow .produkt-col .img .pikto-blend {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(255,255,255,0.84);
  display: none;
}
#produkty #lista-produktow .produkt-col .img .pikto-blend img,
#produkt #lista-produktow .produkt-col .img .pikto-blend img {
  height: 130px;
  position: absolute;
  top: calc(50% - 65px);
}
#produkty #lista-produktow .produkt-col:hover .pikto-blend,
#produkt #lista-produktow .produkt-col:hover .pikto-blend {
  display: block;
}
#produkty #lista-produktow .produkt-col.filary .txt,
#produkt #lista-produktow .produkt-col.filary .txt {
  padding-top: 100px;
}
#produkty #lista-produktow .produkt-col.filary .txt .txt-wrap,
#produkt #lista-produktow .produkt-col.filary .txt .txt-wrap {
  height: 605px;
}
#produkty #lista-produktow .produkt-col.filary .txt p,
#produkt #lista-produktow .produkt-col.filary .txt p {
  height: auto;
  padding-bottom: 50px;
}
#produkty #lista-produktow .produkt-col.filary .txt h2,
#produkt #lista-produktow .produkt-col.filary .txt h2,
#produkty #lista-produktow .produkt-col.filary .txt h2 p,
#produkt #lista-produktow .produkt-col.filary .txt h2 p,
#produkty #lista-produktow .produkt-col.filary .txt p,
#produkt #lista-produktow .produkt-col.filary .txt p,
#produkty #lista-produktow .produkt-col.filary .txt a span,
#produkt #lista-produktow .produkt-col.filary .txt a span {
  color: #4e576d;
}
#produkty #lista-produktow .produkt-col .txt,
#produkt #lista-produktow .produkt-col .txt {
  padding: 30px 55px 75px;
}
#produkty #lista-produktow .produkt-col .txt h2,
#produkt #lista-produktow .produkt-col .txt h2,
#produkty #lista-produktow .produkt-col .txt h2 p,
#produkt #lista-produktow .produkt-col .txt h2 p,
#produkty #lista-produktow .produkt-col .txt h2 p span,
#produkt #lista-produktow .produkt-col .txt h2 p span {
  font-weight: 900;
  font-size: 46px;
  height: 150px;
  margin: 0;
  color: #4e576d !important;
}
@media (min-width: 2200px) {
  #produkty #lista-produktow .produkt-col .txt h2,
  #produkt #lista-produktow .produkt-col .txt h2,
  #produkty #lista-produktow .produkt-col .txt h2 p,
  #produkt #lista-produktow .produkt-col .txt h2 p,
  #produkty #lista-produktow .produkt-col .txt h2 p span,
  #produkt #lista-produktow .produkt-col .txt h2 p span {
    height: 90px;
  }
}
@media (min-width: 2600px) {
  #produkty #lista-produktow .produkt-col .txt h2,
  #produkt #lista-produktow .produkt-col .txt h2,
  #produkty #lista-produktow .produkt-col .txt h2 p,
  #produkt #lista-produktow .produkt-col .txt h2 p,
  #produkty #lista-produktow .produkt-col .txt h2 p span,
  #produkt #lista-produktow .produkt-col .txt h2 p span {
    height: 60px;
  }
}
@media (max-width: 1700px) {
  #produkty #lista-produktow .produkt-col .txt h2,
  #produkt #lista-produktow .produkt-col .txt h2,
  #produkty #lista-produktow .produkt-col .txt h2 p,
  #produkt #lista-produktow .produkt-col .txt h2 p,
  #produkty #lista-produktow .produkt-col .txt h2 p span,
  #produkt #lista-produktow .produkt-col .txt h2 p span {
    font-size: 35px;
    height: auto;
  }
}
@media (max-width: 1450px) {
  #produkty #lista-produktow .produkt-col .txt h2,
  #produkt #lista-produktow .produkt-col .txt h2,
  #produkty #lista-produktow .produkt-col .txt h2 p,
  #produkt #lista-produktow .produkt-col .txt h2 p,
  #produkty #lista-produktow .produkt-col .txt h2 p span,
  #produkt #lista-produktow .produkt-col .txt h2 p span {
    font-size: 30px;
    height: auto;
  }
}
@media (max-width: 1200px) {
  #produkty #lista-produktow .produkt-col .txt h2,
  #produkt #lista-produktow .produkt-col .txt h2,
  #produkty #lista-produktow .produkt-col .txt h2 p,
  #produkt #lista-produktow .produkt-col .txt h2 p,
  #produkty #lista-produktow .produkt-col .txt h2 p span,
  #produkt #lista-produktow .produkt-col .txt h2 p span {
    font-size: 32px;
  }
}
@media (max-width: 1700px) {
  #produkty #lista-produktow .produkt-col .txt h2,
  #produkt #lista-produktow .produkt-col .txt h2 {
    padding-bottom: 20px;
  }
}
#produkty #lista-produktow .produkt-col .txt p,
#produkt #lista-produktow .produkt-col .txt p {
  color: #fff;
  font-size: 26px;
  height: 170px;
}
@media (max-width: 1700px) {
  #produkty #lista-produktow .produkt-col .txt p,
  #produkt #lista-produktow .produkt-col .txt p {
    font-size: 18px;
    height: 100px;
    padding-bottom: 20px;
  }
}
@media (max-width: 1450px) {
  #produkty #lista-produktow .produkt-col .txt p,
  #produkt #lista-produktow .produkt-col .txt p {
    height: auto;
  }
}
@media (max-width: 1200px) {
  #produkty #lista-produktow .produkt-col .txt p,
  #produkt #lista-produktow .produkt-col .txt p {
    height: auto;
  }
}
@media (max-width: 849px) {
  #produkty #lista-produktow .produkt-col .txt p,
  #produkt #lista-produktow .produkt-col .txt p {
    font-size: 16px;
  }
}
@media (max-width: 594px) {
  #produkty #lista-produktow .produkt-col .txt p,
  #produkt #lista-produktow .produkt-col .txt p {
    height: auto;
  }
}
#produkty #lista-produktow .produkt-col .txt .btn-zaffiro-small,
#produkt #lista-produktow .produkt-col .txt .btn-zaffiro-small {
  position: absolute;
  bottom: 38px;
}
@media (max-width: 1700px) {
  #produkty #lista-produktow .produkt-col .txt,
  #produkt #lista-produktow .produkt-col .txt {
    padding: 40px 30px 75px;
  }
}
@media (min-width: 2200px) {
  #produkty #lista-produktow .produkt-col .txt,
  #produkt #lista-produktow .produkt-col .txt {
    padding: 40px 30px 75px;
  }
}
@media (max-width: 1024px) {
  #produkty #lista-produktow .produkt-col .txt,
  #produkt #lista-produktow .produkt-col .txt {
    padding: 40px 55px;
  }
}
@media (max-width: 736px) {
  #produkty #lista-produktow .produkt-col .txt,
  #produkt #lista-produktow .produkt-col .txt {
    padding: 40px 30px;
  }
}
@media (max-width: 549px) {
  #produkty #lista-produktow .produkt-col .txt,
  #produkt #lista-produktow .produkt-col .txt {
    padding: 36px 30px 75px;
  }
}
#szablon {
  color: #4e576d;
}
#szablon .szablon-row:nth-child(2n ) {
  background-color: #f5fafe;
}
#szablon .szablon-row .szablon-text {
  padding: 220px 235px;
}
@media (max-width: 1199px) {
  #szablon .szablon-row .szablon-text {
    padding: 160px 170px;
  }
}
@media (max-width: 991px) {
  #szablon .szablon-row .szablon-text {
    padding: 100px 110px;
  }
}
@media (max-width: 661px) {
  #szablon .szablon-row .szablon-text {
    padding: 40px 30px;
  }
}
#szablon .szablon-row .szablon-text p {
  font-size: 18px;
  margin-bottom: 50px;
}
#szablon .szablon-row .szablon-text h1,
#szablon .szablon-row .szablon-text h2,
#szablon .szablon-row .szablon-text h3 {
  font-size: 39px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 30px;
}
@media (max-width: 667px) {
  #szablon .standard-container .txt-container {
    padding: 10px 15px;
    margin-bottom: 70px;
  }
}
@media (max-width: 435px) {
  #szablon .standard-container .txt-container {
    padding: 50px 30px;
  }
}
@media (min-width: 991px) and (max-width: 1250px) {
  #szablon .standard-container .txt-container {
    padding: 0 54px;
  }
}
@media (min-width: 769px) and (max-width: 990px) {
  #szablon .standard-container .txt-container {
    padding: 0 54px 60px;
  }
}
@media (min-width: 737px) and (max-width: 768px) {
  #szablon .standard-container .txt-container {
    padding: 0 55px 60px;
  }
}
@media (min-width: 436px) and (max-width: 736px) {
  #szablon .standard-container .txt-container {
    padding: 0 30px 60px;
  }
}
#szablon .standard-container .txt-container h1 {
  margin-top: 65px;
  margin-bottom: 65px;
  color: #4e576d;
  font-size: 61px;
}
@media (max-width: 1700px) {
  #szablon .standard-container .txt-container h1 {
    font-size: 46px;
  }
}
@media (min-width: 1100px) and (max-width: 1250px) {
  #szablon .standard-container .txt-container h1 {
    margin-top: 90px;
  }
}
@media (min-width: 991px) and (max-width: 1099px) {
  #szablon .standard-container .txt-container h1 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
@media (max-width: 736px) {
  #szablon .standard-container .txt-container h1 {
    margin: 45px 0;
  }
}
@media (max-width: 435px) {
  #szablon .standard-container .txt-container h1 {
    margin: 0 0 48px;
  }
}
#szablon .standard-container .txt-container h1 p {
  font-size: 61px;
}
@media (max-width: 1700px) {
  #szablon .standard-container .txt-container h1 p {
    font-size: 46px;
  }
}
@media (max-width: 676px) {
  #szablon .standard-container .txt-container h1 p {
    font-size: 39px;
  }
}
#szablon .standard-container .txt-container p,
#szablon .standard-container .txt-container ul,
#szablon .standard-container .txt-container li {
  font-size: 22px;
}
@media (max-width: 1700px) {
  #szablon .standard-container .txt-container p,
  #szablon .standard-container .txt-container ul,
  #szablon .standard-container .txt-container li {
    font-size: 20px;
  }
}
@media (max-width: 991px) {
  #szablon .standard-container {
    height: auto;
  }
  #szablon .standard-container .txt-container {
    height: auto;
  }
}
#szablon .standard-container.right .img-container {
  float: right;
}
@media (max-width: 991px) {
  #szablon .columns-p {
    height: auto;
  }
  #szablon .columns-p .txt-container {
    height: auto;
  }
}
#szablon .standard-container-left,
#szablon .standard-container-right,
#szablon .standard-container {
  clear: both;
  overflow: hidden;
  height: auto;
}
#szablon .standard-container-left.columns-p > .container-fluid > .row,
#szablon .standard-container-right.columns-p > .container-fluid > .row,
#szablon .standard-container.columns-p > .container-fluid > .row {
  height: auto;
  margin-right: -15px;
}
#szablon .standard-container-left .txt-container {
  float: left;
  padding-left: 250px;
}
#szablon .standard-container-left .img-container {
  float: right;
}
#szablon .standard-container-right .txt-container {
  padding-right: 250px;
}
#szablon .standard-container-left.columns-p .txt-container,
#szablon .standard-container-right.columns-p .txt-container {
  padding: 74px 120px 0;
}
@media (max-width: 667px) {
  #szablon .standard-container-left.columns-p .txt-container,
  #szablon .standard-container-right.columns-p .txt-container {
    padding: 10px 15px;
    margin-bottom: 70px;
  }
}
@media (max-width: 435px) {
  #szablon .standard-container-left.columns-p .txt-container,
  #szablon .standard-container-right.columns-p .txt-container {
    padding: 50px 30px;
  }
}
@media (min-width: 991px) and (max-width: 1250px) {
  #szablon .standard-container-left.columns-p .txt-container,
  #szablon .standard-container-right.columns-p .txt-container {
    padding: 0 54px;
  }
}
@media (min-width: 769px) and (max-width: 990px) {
  #szablon .standard-container-left.columns-p .txt-container,
  #szablon .standard-container-right.columns-p .txt-container {
    padding: 0 54px 60px;
  }
}
@media (min-width: 737px) and (max-width: 768px) {
  #szablon .standard-container-left.columns-p .txt-container,
  #szablon .standard-container-right.columns-p .txt-container {
    padding: 0 55px 60px;
  }
}
@media (min-width: 436px) and (max-width: 736px) {
  #szablon .standard-container-left.columns-p .txt-container,
  #szablon .standard-container-right.columns-p .txt-container {
    padding: 0 30px 60px;
  }
}
#szablon .standard-container-left.columns-p .txt-container h1,
#szablon .standard-container-right.columns-p .txt-container h1 {
  margin-top: 65px;
  margin-bottom: 65px;
  color: #4e576d;
  font-size: 61px;
}
@media (max-width: 1700px) {
  #szablon .standard-container-left.columns-p .txt-container h1,
  #szablon .standard-container-right.columns-p .txt-container h1 {
    font-size: 46px;
  }
}
@media (min-width: 1100px) and (max-width: 1250px) {
  #szablon .standard-container-left.columns-p .txt-container h1,
  #szablon .standard-container-right.columns-p .txt-container h1 {
    margin-top: 90px;
  }
}
@media (min-width: 991px) and (max-width: 1099px) {
  #szablon .standard-container-left.columns-p .txt-container h1,
  #szablon .standard-container-right.columns-p .txt-container h1 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
@media (max-width: 736px) {
  #szablon .standard-container-left.columns-p .txt-container h1,
  #szablon .standard-container-right.columns-p .txt-container h1 {
    margin: 45px 0;
  }
}
@media (max-width: 435px) {
  #szablon .standard-container-left.columns-p .txt-container h1,
  #szablon .standard-container-right.columns-p .txt-container h1 {
    margin: 0 0 48px;
  }
}
#szablon .standard-container-left.columns-p .txt-container h1 p,
#szablon .standard-container-right.columns-p .txt-container h1 p {
  font-size: 61px;
}
@media (max-width: 1700px) {
  #szablon .standard-container-left.columns-p .txt-container h1 p,
  #szablon .standard-container-right.columns-p .txt-container h1 p {
    font-size: 46px;
  }
}
@media (max-width: 676px) {
  #szablon .standard-container-left.columns-p .txt-container h1 p,
  #szablon .standard-container-right.columns-p .txt-container h1 p {
    font-size: 39px;
  }
}
#szablon .standard-container-left.columns-p .txt-container p,
#szablon .standard-container-right.columns-p .txt-container p,
#szablon .standard-container-left.columns-p .txt-container ul,
#szablon .standard-container-right.columns-p .txt-container ul,
#szablon .standard-container-left.columns-p .txt-container li,
#szablon .standard-container-right.columns-p .txt-container li {
  font-size: 22px;
}
@media (max-width: 1700px) {
  #szablon .standard-container-left.columns-p .txt-container p,
  #szablon .standard-container-right.columns-p .txt-container p,
  #szablon .standard-container-left.columns-p .txt-container ul,
  #szablon .standard-container-right.columns-p .txt-container ul,
  #szablon .standard-container-left.columns-p .txt-container li,
  #szablon .standard-container-right.columns-p .txt-container li {
    font-size: 20px;
  }
}
#szablon .standard-container-left.columns-p .img-container,
#szablon .standard-container-right.columns-p .img-container {
  background-size: cover;
  background-repeat: no-repeat;
  height: 700px;
}
@media (max-width: 676px) {
  #szablon .standard-container-left.columns-p .img-container,
  #szablon .standard-container-right.columns-p .img-container {
    height: 350px;
    background-size: cover;
  }
}
.container-404 h1 {
  font-size: 487px;
  text-align: center;
  background: url("../img/main/404bg.png") no-repeat calc(50% - 419px) center;
  line-height: 400px;
  margin-top: 100px;
  margin-bottom: 20px;
}
.container-404 h1:first-letter {
  color: #4e576d;
}
.container-404 .no-page-found {
  font-size: 18px;
  text-align: center;
}
.container-404 .no-page-found a {
  color: #babec7;
}
.container-404 .go-to-pages {
  margin-top: 20px;
  padding-bottom: 100px;
}
.container-404 .go-to-pages .row {
  height: auto;
}
.container-404 .go-to-pages .row h3 {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #4e576d;
}
.container-404 .go-to-pages .row p {
  padding-top: 10px;
  font-size: 14px;
  color: #babec7;
  text-align: center;
}
.blueimp-gallery {
  background-color: rgba(78,87,109,0.88);
  z-index: 2000000;
  display: none;
}
.blueimp-gallery .next,
.blueimp-gallery .prev {
  width: 10px;
  height: 60px;
  border: 0;
  background: url("../img/strzalka-lewo-white.png") transparent no-repeat left center;
  font-size: 0;
  background-size: contain;
}
.blueimp-gallery .next {
  transform: rotate(180deg);
}
.galeria-zdjec {
  display: flex;
  padding: 0;
  height: auto;
  position: relative;
  clear: both;
  width: calc(100% - 100px);
  margin: 60px auto;
}
@media (max-width: 1450px) {
  .galeria-zdjec {
    width: 100%;
  }
}
.galeria-zdjec::after {
  content: "";
  flex: auto;
}
.galeria-zdjec .gallery-move {
  position: absolute;
  width: 10px;
  height: 100%;
  top: 0;
  background: url("../img/strzalka-lewo.png") no-repeat left center;
  background-size: contain;
  cursor: pointer;
  transition: opacity 0.5s;
  z-index: 999;
}
@media (max-width: 1450px) {
  .galeria-zdjec .gallery-move {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}
.galeria-zdjec .gallery-move.left {
  left: -30px;
}
.galeria-zdjec .gallery-move.right {
  right: -30px;
  transform: rotate(180deg);
}
.galeria-zdjec .gallery-move.disabled {
  opacity: 0.1;
  cursor: not-allowed;
}
.galeria-zdjec .gallery-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.galeria-zdjec .gallery-container .item-container {
  position: absolute;
}
.galeria-zdjec .gallery-container .item-container .item {
  width: 100%;
  height: 100%;
  background-position: center center !important;
  background-size: cover;
}
.galeria-zdjec-full {
  display: flex;
  padding: 0;
  height: auto;
  position: relative;
  clear: both;
}
.galeria-zdjec-full .gallery-move {
  position: absolute;
  width: 53px;
  height: 100px;
  background: #f00;
  top: calc(50% - 50px);
  background: url("../img/gallery-arrow-white.png") no-repeat left center;
  cursor: pointer;
  transition: opacity 0.5s;
  z-index: 999;
}
.galeria-zdjec-full .gallery-move.left {
  left: 53px;
}
.galeria-zdjec-full .gallery-move.right {
  right: 53px;
  transform: rotate(180deg);
}
.galeria-zdjec-full .gallery-move.disabled {
  opacity: 0.1;
  cursor: not-allowed;
}
.slick-gallery {
  width: 100%;
}
.slick-gallery .slide {
  width: auto;
  position: relative;
}
.slick-gallery .slide:not(.slick-active)::after {
  background-color: rgba(0,92,156,0.8);
  width: 100%;
  height: 100%;
  content: '';
  top: 0;
  left: 0;
  position: absolute;
}
.slick-gallery .slide img {
  width: 85vw;
}
