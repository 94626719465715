standard-container-box-sizes = 700px

.maps
	padding-right: 0
	padding-left: 0

	> div
		height: standard-container-box-sizes !important
	
	@media( max-width: 767px )
		margin-top: 30px

		> div
			height: 320px !important

.top-normal-img
	height: standard-container-box-sizes
	position: relative

	> .container-fluid

		> .row
			margin: 0 -15px

	.row

		> div
			height: 100%
			min-height: 100%

			@media( max-width: 736px )
				height: auto
				min-height: 0

			&.img-container
				height: standard-container-box-sizes
				background-size: cover

			&.txt-container
				padding: 109px 55px
				color: steel-color
				font-size: 28px

				@media( max-width: 1024px )
					padding: 45px 55px


				@media( max-width: 736px )
					padding: 45px 30px

				@media( max-width: 414px )
					padding: 30px

	.half-logo
		left: calc( 25% - 24px )
		top: calc( 50% - 20px )
		
		@media( max-width: 1199px )
			left: calc( 33.46% - 25px )


.top-normal-img,
.standard-container-right,
.standard-container-left,
.standard-container
	font-size: 20px
	color: blue-color

	h1
		font-size: 52px
		font-weight: 900
		margin-bottom: 66px
		margin-top: 0
		color: steel-color

		@media( max-width: 736px )
			margin-bottom: 40px
			font-size: 40px

		span
			color: blue-color

.standard-container-right,
.standard-container-left,
.standard-container
	overflow: hidden
	min-height: standard-container-box-sizes
	position: relative

	@media ( max-width: 736px )
		min-height: 0

	&.gray
		background: light-gray-color

	> .row
		margin: 0
	
	.row
		height: standard-container-box-sizes
		margin-right: 0

		> div
			height: 100%

			&.img-container
				background-size: cover

			&.txt-container
				padding: 74px 56px
				color: blue-color
				font-size: 26px

				@media( max-width: 1024px )
					padding: 45px 55px

				@media( max-width: 736px )
					padding: 45px 30px

				@media( max-width: 414px )
					padding: 30px

				h1
					font-size: 46px
					font-weight: 900
					margin-bottom: 283px
					color: steel-color
					
					@media( max-width: 991px )
						margin-bottom: 0

					@media( max-width: 736px )
						font-size: 39px

					@media( max-width: 414px )
						margin-bottom: 21px !important
					
					span
						color: blue-color

	

	.btn-zaffiro
		position: absolute
		bottom: 71px
		left: 54px

		@media( max-width: 1024px )
			left: 55px

		@media( max-width: 736px )
			left: 30px


.half-logo
	height: 40px
	width: 48px
	position: absolute

	img
		max-width: 100%
		max-height: 100%