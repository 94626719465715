light-steel-color = #babec7
light-gray-color = #f5fafe

border-gray-color = #e4e4e4

steel-color = #abb2bb
blue-color = #4e576d

dark-blue-color = #3d4557
dark-light-steel-color = #a4a8b0

a:hover,
a:focus,
a:active
	text-decoration: none
	
.no-scroll
	position: fixed;
	overflow hidden;
	width: 100%;

body
	font-family: 'Source Sans Pro', sans-serif
	.btn-zaffiro
		display: inline-flex !important
		align-items: center
		text-decoration: none

		.ico-holder
			display: inline-block;
			border-radius: 3px
			border: 0

	.btn-zaffiro-white

		&:hover

			.ico-holder
				background: blue-color

				svg

					path
						fill: #fff

		.ico-holder
			background: #fff
			-webkit-transition: background-color 200ms linear
			transition: background-color 200ms linear

			svg

				path
					fill: blue-color
					-webkit-transition: fill 200ms linear
					transition: fill 200ms linear

		span
			color: #fff

	.btn-zaffiro-blue

		&:hover

			.ico-holder
				background: #fff

				svg

					path
						fill: blue-color

		.ico-holder
			background: blue-color
			-webkit-transition: background-color 200ms linear
			transition: background-color 200ms linear

			svg

				path
					fill: #fff
					-webkit-transition: fill 200ms linear
					transition: fill 200ms linear

		span
			color: blue-color

	.btn-zaffiro-small
		line-height: 0

		.ico-holder
			padding: 9px 9px !important
			width: 31px

			svg
				height: 11px
				display: block

		span
			padding-left: 11px
			font-size: 19px

	.btn-zaffiro-big

		.ico-holder
			padding: 13px 13px
			max-width: 58px
			
			@media (min-width: 1681px)
				padding: 17px 17px

			@media (min-width: 481px)
				padding: 17px 17px

			svg
				height: 20px
				display: block

		span
			padding-left: 10px
			font-size: 23px
			
			@media (min-width: 1681px)
				padding-left: 21px
				font-size: 33px

			@media (min-width: 481px)
				padding-left: 15px
				font-size: 28px

	button.btn-zaffiro
		border-radius: 3px
		border: 0

	button.btn-zaffiro-white
		background: #fff
		color: #fff
		transition: all 0.4s ease
		
		&:hover
			background: light-steel-color

	button.btn-zaffiro-blue
		background: blue-color
		color: #fff
		transition: all 0.4s ease
		
		&:hover
			background: steel-color

	button.btn-zaffiro-small
		line-height: initial
		padding: 13px 34px
		font-size: 15px
		font-weight: bold

		@media(max-width:380px)
			padding: 13px 18px
		@media(max-width:350px)
			padding: 13px 5px

	button.btn-zaffiro-big
		padding: 22px 17px

	.button-group
		
		button

			&:first-child
				float: left
				margin-right: 14px
				@media(max-width:350px)
					margin-right: 7px

			&:last-child
				padding: 13px 12px
			
			img
				height: 14px

	footer
		padding: 45px 0 0 0
		color: #4a566c
		background: light-steel-color
		display: flex
		flex-wrap: wrap
		width: 100%
		position: relative

		@media ( max-width: 1024px )
			padding-left: 55px

		@media ( max-width: 736px )
			padding-left: 30px

		.scroll-top
			display: block
			position: absolute
			right: 45px
			top: -43px
			cursor: pointer

			img
				width: 45px
				height: 45px
		
		a
			color: #4a566c
			
			&:hover, &:visited, &:focus
				text-decoration: none
				color: #4a566c

		> div
			display: block
			float: left
			color: blue-color
			font-size: 14px
			margin-bottom: 45px
			padding: 0 20px

			@media (min-width: 1401px) and (max-width: 1601px)
				padding: 0 10px

			@media ( max-width: 1024px )
				padding: 0 55px 0 0

			@media ( max-width: 736px )
				padding: 0 30px 0 0

			h3
				margin: 0 0 29px 0
				text-transform: uppercase
				font-size: 12px
				font-weight: bold

			h4
				margin: 0 0 29px 0
				font-size: 14px

		.footer-zaffiro-logo
			width: 13.5%
			text-align: center

			@media (max-width: 1440px)
				width: 150px

			@media (max-width: 355px)
				width: 100%
				text-align: left

			img
				width: 116px
				margin-top: 13px

		.footer-womar-logo
			width: 11.3%
			text-align: center

			@media (max-width: 1440px)
				width: calc(100% - 150px)
				text-align: left

			@media (max-width: 1024px)
				text-align: left

			@media (max-width: 355px)
				width: 100%

			img
				width: 124px
				margin-top: 13px

		.footer-address
			width: 12.6%

			@media (max-width: 1440px)
				width: 25%

			@media (max-width: 650px)
				width: 100%

		.footer-contact
			width: 12.55%

			@media (max-width: 1440px)
				width: 25%

			@media (max-width: 650px)
				width: 100%

				h3
					display: none

		.footer-menu
			width: 8.3%

			@media (max-width: 1440px)
				width: 25%

			@media (max-width: 650px)
				width: 40%

		.footer-menu,
		.footer-linki
			ul
				padding: 0
				margin: 0

				li
					list-style-type: none

		.footer-linki
			width: 9.94%

			@media (max-width: 1440px)
				width: 25%

			@media (max-width: 650px)
				width: 60%

		.footer-sklep
			width: 10.83%

			@media (max-width: 1440px)
				width: 50%

				> div
					width: 32%
					float: right

			@media (max-width: 1024px)
				width: 25%

				> div
					width: 100%
					float: left

			@media (max-width: 650px)
				width: 100%

				> div
					width: 100%
					max-width: 150px

			.btn
				width: 100%
				text-align: center
				background: blue-color
				color: #fff
				padding: 8px 12px

		.footer-newsletter
			width: 19.5%

			@media (max-width: 1440px)
				width: 50%

				> div
					width: 49%
					display: inline-block

			@media (max-width: 1024px)
				width: 75%

			@media (max-width: 650px)
				width: 100%

				> div

					&:first-element
						width: 70%
					&:last-element
						width: 30%

			@media (max-width: 570px)

				> div
					width: 100%

			input
				width: calc( 100% - 100px )
				float: left

			button
				background: blue-color
				height 38px
				margin-left: 11px
				color: #fff

			.footer-socials
				margin-top: 20px

				a
					padding-right: 14px

		.pleo-footer
			position: absolute
			right: 35px
			bottom: 25px
			outline: 0
			text-decoration: none

			span
				padding-right: 10px
				font-size 12px

			svg
				display: inline-block
				float: right
				margin-top: 3px			
		
		.row
			margin-left: 0
			margin-right: 0


	input[type=text],
	textarea.form-control
		border: 2px solid border-gray-color

	input[type=text]
		height:38px

	.zaffiro-submit,
	.zaffiro-submit:hover,
	.zaffiro-submit:active
		background: blue-color
		padding: 16px 36px
		border-radius: 6px
		color: white
		font-weight: bold
		
	.pos-goraLewo
		background-position: left top

	.pos-goraSrodek
		background-position: center top

	.pos-goraPrawo
		background-position: right top

	.pos-srodekLewo
		background-position: left center

	.pos-srodekSrodek
		background-position: center center

	.pos-srodekPrawo
		background-position: right center

	.pos-dolLewo
		background-position: left bottom

	.pos-dolSrodek
		background-position: center bottom

	.pos-dolPrawo
		background-position: right bottom

	.newsletter-form
		margin-top: -3px

.horizontal-mobile
	position: fixed
	z-index: 999999999
	background-color: #fff
	width: 100%
	height: 200%
	top: -50%
	left: 0
	display: none
	padding: 35px 50px

	> div
		height: 100%

		img
			position: absolute
			top: 28%
			left: 0
			width: 100px
		
		.txt
			left: 0
			bottom: 30%
			position: absolute

			h1
				font-size: 48px
				font-weight: 900
				color: steel-color

				span
					color: blue-color
			
			p
				color: blue-color
				font-size: 14px


		.svg
			position: absolute
			top: 50%;
			transform: translateY(-50%);