#gdzie-kupic
	
	.top-normal-img
		height: auto
		
		.txt-container
			font-size: 20px
			color: blue-color

		.container-fluid

			@media (min-width: 736px)
				padding-right: 0

				.row
					margin-right: 0
	
	.sklepy-section
		
		.txt-container
				
			@media( max-width: 991px )
				padding-top: 0 !important

			h1
				margin-bottom: 70px
				
		.standard-container-left
			.row
				& > div.txt-container
					padding: 74px 56px 0px

		.sklepy-container

			.row
				display: flex
				flex-wrap: wrap
				
				@media( max-width: 736px )
					text-align: center

				img
					max-width: 100%
	
	.standard-container-left
		height: auto
		min-height: 0
		
		.row
			height: auto

			.txt-container
			
				@media( max-width: 1024px )
					padding: 45px 55px

				@media( max-width: 736px )
					padding: 45px 30px
					text-align: left

					h1
						margin-bottom: 0

				
				
				@media( max-width: 375px )
					padding: 30px
	
		.sklepy-container
			padding-top: 74px

			@media( max-width: 1024px )
				padding: 45px 0

			@media( max-width: 991px )
				padding: 0 55px 55px

			@media( max-width: 736px )
				padding: 0 30px 45px


			& > .row
				height: auto
				display: flex
				flex-wrap: wrap

				@media( max-width: 991px )
					margin: 0
			
			.localization
				color: blue-color
				height: auto

				@media( max-width: 991px )
					padding: 0 40px 0 0
				
				h3
					font-size: 16px
					font-weight: 900
					color: steel-color
					margin: 0
					height: 17px
					display: inline-block

					@media( max-width: 991px )
						padding-left: 40px
						line-height: 2em
						padding: 0

				.row
					display: flex
					flex-wrap: wrap

				.sklep
					font-size: 14px
					margin-bottom: 60px

					@media( max-width: 991px )
						padding: 0 40px
						margin-bottom: 0
						padding: 0 0 50px
					
					strong
						margin-top: 20px
						margin-bottom: 15px
						display: block
						
					p
						margin-bottom: 20px
						
					a
						text-decoration: underline
						color: blue-color
						font-style: italic
			.col-lg-3
				@media(min-width: 1200px) 
					width: 24.5%
				@media (min-width: 992px) and (max-width: 1200px)
					width: 33%
				@media(max-width: 991px) and (min-width:650px)
					width: 49%
			.col-md-3
				@media(min-width: 1200px) 
					width: 24.5%
			.col-sm-3
				@media (min-width: 768px)
					width: 24.5%
			.col-xs-6
				@media (max-width: 767px)
					width: 49%

						
	#wyszukaj
		
		margin-top: 50px
		
		input
			border: 2px solid blue-color
			border-radius: 10px
			padding: 20px 15px
			outline: none
			font-size: 16px
			width: 100%

			@media ( max-width: 991px ) and ( min-width: 426px )
				width: calc( 100% - 121px)
			
		a
			border-radius: 10px
			background-color: blue-color
			color: #fff
			font-weight: 900
			font-size: 16px
			padding: 12px 35px
			margin-top: 10px
			display: inline-block

	#search-result
		margin-top: 20px

		.results
			height: 136px
			overflow-y: auto

			.sklep
				border-bottom: 1px solid blue-color
				margin: 20px

				@media ( max-width: 736px )
					margin: 0

				div
					margin-bottom: 20px

				.nazwa
					font-weight: bold

	#geolocalization
		font-size: 11px
		margin-top: 20px

		a
			color: blue-color

			span
				text-decoration: underline

			img
				margin-left: 10px

	.infowindow
		border-radius: 5px