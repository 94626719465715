#filary

	.top-normal-img

		.txt-container
			color: blue-color
			font-size: 20px 

			h1
				
				p
					
					@media( max-width: 736px )
						font-size: 40px

			p
				@media( max-width: 736px )
					font-size: 20px

		@media( max-width: 991px )
			height: auto

			.img-container
				display: none !important
		
	.half-logo
		z-index: 999999
		@media( max-width: 991px )
			display: none !important

	.img-container
		z-index: 9999
		
		@media( max-width: 991px )
			display: block !important
			height: 350px

	.txt-container
		z-index: 9999
		background-color: #fff;
		
		h1
			margin-bottom: 50px
			
			p
				font-size: 46px
				
				@media( max-width: 736px )
					font-size: 39px
			
		@media( max-width: 1550px )
			padding-top: 30px

			h1
				margin-bottom: 40px

			p
				font-size: 18px
		
		@media( max-width: 991px )
			display: block
			min-height: 450px
			height: auto

			.btn-zaffiro
				position: absolute
				bottom: 30px
				left: 70px


		@media( max-width: 1024px )
			padding: 45px 55px


			.btn-zaffiro
				bottom: 30px
				left: 55px



		@media( max-width: 736px )
			padding: 45px 30px


			.btn-zaffiro
				bottom: 30px
				left: 30px


		p
			font-size: 19px
			@media(min-width: 992px) and (max-width: 1300px)
				font-size:18px

	.standard-container
		width: 100%
		
		@media( max-width: 991px )
			height: auto
		
		&.left

			.half-logo
				left: calc( 75% - 25px )
				top: calc( 50% - 20px )
				
				@media( max-width: 1199px )
					left: calc( 66.69% - 25px )

		&.right
			
			.img-container
				float: right

			.half-logo
				left: calc( 25% - 24px )
				top: calc( 50% - 20px )
				
				@media( max-width: 1199px )
					left: calc( 33.46% - 25px )
				
		.rozwin
			position: absolute
			top: 0
			left: 0
			height: 100%
			width: 100%
			overflow: hidden
			
			.btn-zaffiro
				position: absolute
				bottom: 30px
				
				@media( max-width: 375px )
					left: 30px
			
			@media( max-width: 991px )
				top: 350px
			
			.content
				padding: 90px 150px

				@media (max-width: 991px)
					padding: 0
			
			.close
				display: block
				position: absolute
				top: 80px
				right: 100px
				background-repeat: no-repeat
				background-size: cover
				height: 20px
				width: 20px
				opacity: 1
				cursor: pointer
				
				@media( max-width: 991px )
					z-index: 100
					top: 20px
					right: 30px

			.content

				@media( max-width: 991px )
					display: block
			
				.content-item
					
					@media( max-width: 991px )
						position: absolute
						height: 350px
						background-color: #fff
						display: block
						width: 100%
						padding: 20px 53px 67px
						white-space: normal
						left: 100%;

					
				
					@media( max-width: 991px )
						padding: 45px 55px

					@media( max-width: 736px )
						padding: 45px 30px


					h1
						color: light-steel-color
						font-size: 39px
						font-weight: 700
						margin-top: 70px

						@media( max-width: 1200px )
							font-size: 35px
						
						@media( max-width: 991px )
							margin-top: 20px !important

						@media( max-width: 736px )
							margin-top: 0 !important
						
					&:first-child
						h1
							margin-top: 0
							margin-bottom: 10px

						@media( max-width: 991px )
							left: 0
						
					h2
						color: light-steel-color
						font-size: 41px
						font-weight: 700
						
						@media( max-width: 1200px )
							font-size: 35px

						@media( max-width: 736px )
							margin-top: 0 !important
						
					p
						color: blue-color
						margin-bottom: 50px
						color: blue-color
						font-size: 21px
						
						@media( max-width: 1550px )
							font-size: 18px
				
						@media( max-width: 991px )
							margin-bottom: 20px
						
			.next
				@media( min-width: 992px )
					display: none !important
			.zwin-left
				left:78% !important
				bottom: 71px
				@media( max-width: 991px )
					display: none !important
			.zwin-right
				left: 3%
				bottom: 71px
				@media( max-width: 991px )
					display: none !important
				

			
				