#szablon
	
	color: blue-color
	
	.szablon-row
		&:nth-child( 2n )
			background-color: light-gray-color
			
		.szablon-text
			padding: 220px 235px
			
			@media( max-width: 1199px )
				padding: 160px 170px
			@media( max-width: 991px )
				padding: 100px 110px
			@media( max-width: 661px )
				padding: 40px 30px				
			
			p
				font-size: 18px
				margin-bottom: 50px;
				
			h1,h2,h3
				font-size: 39px;
				font-weight: 700;
				margin-top: 0
				margin-bottom: 30px
			
	.standard-container
		
		.txt-container
			
			@media( max-width: 667px )
				padding: 10px 15px
				margin-bottom: 70px

			@media( max-width: 435px )
				padding: 50px 30px

			@media( min-width: 991px ) and ( max-width: 1250px )
				padding: 0 54px

			@media( min-width: 769px ) and ( max-width: 990px )
				padding: 0 54px 60px

			@media( min-width: 737px ) and ( max-width: 768px )
				padding: 0 55px 60px

			@media( min-width: 436px ) and ( max-width: 736px )
				padding: 0 30px 60px
			
			h1
				margin-top: 65px
				margin-bottom: 65px
				color: blue-color
				font-size: 61px
				
				@media( max-width: 1700px )
					font-size: 46px

				@media( min-width: 1100px ) and ( max-width: 1250px )
					margin-top: 90px

				@media( min-width: 991px ) and ( max-width: 1099px )
					margin-top: 60px
					margin-bottom: 60px

				@media( max-width: 736px )
					margin: 45px 0

				@media( max-width: 435px )
					margin: 0 0 48px
				
				p
					font-size: 61px
					
					@media( max-width: 1700px )
						font-size: 46px
					
					@media( max-width: 676px )
						font-size: 39px
					
			p, ul, li
				font-size: 22px
				
				@media( max-width: 1700px )
					font-size: 20px
		
		@media( max-width: 991px )
			height: auto
			
			.txt-container
				height: auto

		&.right
			
			.img-container
				float: right
				
	.columns-p
		
		@media( max-width: 991px )
			height: auto
			
			.txt-container
				height: auto
				
	.standard-container-left,
	.standard-container-right,
	.standard-container
		clear: both
		overflow: hidden
		height: auto
		
		&.columns-p

			> .container-fluid

				> .row
					height: auto
					margin-right: -15px
	
	.standard-container-left
		
		.txt-container
			float: left
			padding-left: 250px

		.img-container
			float: right
	
	.standard-container-right
		
		.txt-container
			padding-right: 250px			

	.standard-container-left,
	.standard-container-right
			
		&.columns-p
						
			.txt-container
				
				padding: 74px 120px 0
				
				@media( max-width: 667px )
					padding: 10px 15px
					margin-bottom: 70px

				@media( max-width: 435px )
					padding: 50px 30px

				@media( min-width: 991px ) and ( max-width: 1250px )
					padding: 0 54px

				@media( min-width: 769px ) and ( max-width: 990px )
					padding: 0 54px 60px

				@media( min-width: 737px ) and ( max-width: 768px )
					padding: 0 55px 60px

				@media( min-width: 436px ) and ( max-width: 736px )
					padding: 0 30px 60px
				
				h1
					margin-top: 65px
					margin-bottom: 65px
					color: blue-color
					font-size: 61px
					
					@media( max-width: 1700px )
						font-size: 46px

					@media( min-width: 1100px ) and ( max-width: 1250px )
						margin-top: 90px

					@media( min-width: 991px ) and ( max-width: 1099px )
						margin-top: 60px
						margin-bottom: 60px

					@media( max-width: 736px )
						margin: 45px 0

					@media( max-width: 435px )
						margin: 0 0 48px
					
					p
						font-size: 61px
						
						@media( max-width: 1700px )
							font-size: 46px
						
						@media( max-width: 676px )
							font-size: 39px
						
				p, ul, li
					font-size: 22px
					
					@media( max-width: 1700px )
						font-size: 20px
					
			.img-container
				background-size: cover
				background-repeat: no-repeat
				height: 700px
				
				@media( max-width: 676px )
					height: 350px
					background-size: cover