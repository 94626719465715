#o-nas

	.top-normal-img
				
		.txt-container
			color: blue-color
			font-size: 20px
		
			> p
		
				@media( min-width: 1700px )
					font-size: 24px

		@media( max-width: 991px )
			height: auto

	.standard-container
		
		@media( max-width: 991px )
			height: auto
			
			.row
				height: auto
				
		.txt-container
			h1
				margin-bottom: 45%
				
				@media( max-width: 991px )
					margin-bottom: 45px
			p
				font-size: 20px
				
	.img-container
		@media( max-width: 991px )
			height: 450px
				
	#design, .design
		background-color: #f4faff

		&:nth-child( 2n+1 )
			background: white

		.container-fluid

			@media( max-width: 1024px )
				padding: 0px

			.standard-container
				width: 100%

				> .row
					width: 80%
					margin: 0 auto

					@media( max-width: 1024px )
						width: 100%

				.txt-container

					> div

						@media( min-width: 992px )
							position: relative
							top: 50%
							transform: translateY(-50%)

					h1
						margin-top: 0
						margin-bottom: 65px
						
						@media( max-width: 991px )
							margin-top: 40px

						@media( max-width: 736px )
							font-size: 39px
							margin: 0 0 45px 0
							

				.img-container
					background-size: contain
					background-repeat: no-repeat
					background-position: center bottom
					
					@media( max-width: 991px )
						height: 400px

	.design
		.container-fluid
			.standard-container
				.txt-container
					float: right
					&:nth-child( 2n )
						float: none
				.img-container
					float: left
					&:nth-child( 2n )
						float: none

				@media( max-width: 991px )
					.img-container,
					.txt-container
						float: none
				
	#nagrody
		background-color: #fff
		
		.standard-container
			width: 100%

			> .row
				width: 80%
				margin: 0 auto

				@media( max-width: 1024px )
					width: 100%

				.txt-container

					> div
						margin-top: 50%
						transform: translateY( -50% )
					
						@media( max-width: 1024px )
							margin-top: 0
							transform: none

						h1
							margin-bottom: 40px

			
			.nagrody-cont
				margin-top: 120px
				height: auto
				
				@media( min-width: 1024px )
					float: left
					padding-left: 55px
					
				@media( max-width: 991px )
					margin-top: 60px
				
				.nagroda-item
					img
						display: block
						margin: 0 auto 45px
						max-width: 70%
						
						@media( min-width: 992px )
							max-width: 130px
							
					&:nth-child( 3n - 2 )
						img
							@media( min-width: 992px )
								margin-right: 30px
					&:nth-child( 3n - 1 )
						img
							@media( min-width: 992px )
								margin: 0 auto
						
			.txt-container
				@media( min-width: 678px )
					float: right

			.img-container
				
				&.strzalki
					position:relative
					
					.lewo,.prawo
						position: absolute
						top: 25%
						cursor: pointer
					
					.lewo
						left: 30px
						
						@media( max-width: 550px )
							left: 10px
					
					.prawo
						right: 30px
						
						@media( max-width: 550px )
							right: 10px

				img
					max-width: 80%
					display: block
					margin: 0 auto
					margin-top: 145px
		
	#map-bottom
		background-color: #f4faff
		padding: 140px 0
		background-repeat: no-repeat
		background-position: right center
		@media(max-width:992px)
			background-image: none !important
		
		@media( min-width: 992px ) 
			.map-img
				display: none !important
		
		@media( max-width: 1024px )
			padding: 30px 0

		.container-fluid

			@media( max-width: 1024px )
				padding: 0px
		
			.standard-container
				width: 100%

				> .row
					width: 80%
					margin: 0 auto

					@media( max-width: 1024px )
						width: 100%


			.container
				@media( max-width: 1024px )
					padding: 0px
			img
				max-width: 100%
				display: block
				margin: 0 0 30px
		
				&.map-img
					margin: 0 -30px
					width: calc( 100% + 60px )
					max-width: initial
		@media( max-width: 1200px )
			background-size: contain