.blueimp-gallery
	background-color: rgba( 78, 87, 109, 0.88 )
	z-index: 2000000
	display: none

	.next,
	.prev
		width: 10px
		height: 60px
		border: 0;
		background: url('../img/strzalka-lewo-white.png') transparent no-repeat left center;
		font-size: 0;
		background-size: contain;

	.next
		transform: rotate(180deg);

.galeria-zdjec
	display: flex
	padding: 0
	height: auto
	position: relative
	clear: both
	width: calc( 100% - 100px );
	margin: 60px auto;
		
	@media( max-width: 1450px )
		width: 100%;

	&::after 
		content: "" 
		flex: auto

	.gallery-move
		position: absolute
		width: 10px
		height: 100%
		top: 0
		background: url('../img/strzalka-lewo.png') no-repeat left center
		background-size: contain;
		cursor: pointer
		transition: opacity .5s
		z-index: 999

		@media( max-width: 1450px )
			opacity: 0;
			visibility: hidden
			display: none

		&.left
			left: -30px

		&.right
			right: -30px
			transform: rotate(180deg)

		&.disabled
			opacity: .1
			cursor: not-allowed

	.gallery-container 
		width: 100%
		height: 100%
		overflow: hidden
		position: relative

		.item-container
			position: absolute

			.item
				width: 100%
				height: 100%
				background-position: center center !important
				background-size: cover

.galeria-zdjec-full
	display: flex
	padding: 0
	height: auto
	position: relative
	clear: both

	.gallery-move
		position: absolute
		width: 53px
		height: 100px
		background: red
		top: calc(50% - 50px)
		background: url('../img/gallery-arrow-white.png') no-repeat left center
		cursor: pointer
		transition: opacity .5s
		z-index: 999

		&.left
			left: 53px

		&.right
			right: 53px
			transform: rotate(180deg)

		&.disabled
			opacity: .1
			cursor: not-allowed

.slick-gallery
	width: 100%

	.slide
		width: auto
		position: relative
		&:not(.slick-active)::after
			background-color: rgba(0, 92, 156, 0.8)
			width: 100%
			height: 100%
			content: ''
			top: 0
			left: 0
			position: absolute

		img
			width: 85vw
