#produkty, #produkt	
	#lista-produktow
		
		.row
			> div
				margin-top: 0
		
		.produkt-col
			height: 700px
			padding: 0
			background-color: light-steel-color
			float: left
			cursor: pointer
			position: relative
			
			&:hover
				background-color: dark-light-steel-color
				
			@media( min-width: 1700px )
				height: 960px
				
				.txt
					h2
						margin-top: 10px
			
			@media( min-width: 1400px )
						
				&.start
					width: 25%					
			
				&.narrow-col
					width: 25%
					
					.wide-img
						display: none
					
				&.wide-col
					width: 50%
					
					.narrow-img
						display: none
					
			@media( max-width: 1399px ) and ( min-width: 850px )
				
				&.start
					width: 33.333333% !important
				
				&.narrow-col
					width: 33.333333%
					.wide-img
						display: block
					
				&.wide-col
					width: 66.666666%
					
					.narrow-img
						display: none
					
				&:nth-child( 2 )
					width: 66.666666% !important
					
					.narrow-img
						display: none
					
				&:nth-child( 4n - 3)
					width: 33.333333%
					.wide-img
						display: none
					
					.narrow-img
						display: block
				
				&:nth-child( 4n - 2 )
					width: 66.666666%
					
					.narrow-img
						display: none

				&:nth-child( 4n - 1 )
					width: 66.666666%
					
					.narrow-img
						display: none				

				&:nth-child( 4n )
					width: 33.333333%

					.wide-img
						display: none
					
					.narrow-img
						display: block
			
			@media( max-width: 849px ) and ( min-width: 710px )
				&
					height: 700px
					
			@media( max-width: 709px ) and ( min-width: 550px )
				&
					height: 635px
					
			@media( max-width: 849px ) and ( min-width: 550px )
					
				&.start
					width: 50%
				
				&:nth-child( 3n - 2 )
					width: 50%
					.wide-img
						display: none
				
				&:nth-child( 3n - 1 )
					width: 50%
					.wide-img
						display: none
				
				&:nth-child( 3n )
					width: 100%
					
					.narrow-img
						display: none
					
			@media( max-width: 549px )
				height: auto

				&.start
					width: 100%
					
					.wide-img
						display: none
				
				&.narrow-col
					width: 100%
					
					.wide-img
						display: none
				
				&.wide-col
					width: 100%
					
					.wide-img
						display: none
			
			&:nth-child( 2n )
				background-color: blue-color
				
				&:hover
					background-color: dark-blue-color
				
				.txt
				
					h2, h2 p, h2 p span
						color: light-steel-color !important
			
			&.filary
				background-color: #fff

			&.start
				background-color: #fff
				padding: 109px 55px
				
				@media( max-width: 1024px )
					padding: 45px 55px
				
				@media( max-width: 736px )
					padding: 45px 30px

				h1, h1 p
					font-size: 52px !important
					color: steel-color
					font-weight: 900
					margin-bottom: 66px
					margin-top: 0
				
					@media( max-width: 999px )
						font-size: 38px !important
					
					span
						color: blue-color
						
				p
					font-size: 20px
					color: blue-color
			
			.img
				position: relative
				
				img
					width: 100%
					display: block
					margin: 0 auto
					
				.pikto-blend
					position: absolute
					width: 100%
					top: 0
					left: 0
					height: 100%
					background-color: rgba( 255, 255, 255 , 0.84 );
					display: none
					
					img
						height: 130px
						position: absolute
						top: calc( 50% - 65px )
					
			&:hover
				.pikto-blend
					display: block
					
			&.filary
				.txt
					padding-top: 100px
					
					.txt-wrap
						height: 605px
					
					p
						height: auto
						padding-bottom: 50px

					h2,h2 p, p,a span
						color: blue-color

			.txt
				padding: 30px 55px 75px
				
				h2, h2 p, h2 p span
					font-weight: 900
					font-size: 46px
					height: 150px
					margin: 0
					color: blue-color !important

					@media( min-width: 2200px )
						height: 90px

					@media( min-width: 2600px )
						height: 60px
					
					@media( max-width: 1700px )
						font-size: 35px
						height: auto
					
					@media( max-width: 1450px )
						font-size: 30px
						height: auto
					
					@media( max-width: 1200px )
						font-size: 32px

				h2
					
					@media( max-width: 1700px )
						padding-bottom: 20px
					
				p
					color: #fff
					font-size: 26px
					height: 170px
					
					@media( max-width: 1700px )
						font-size: 18px
						height: 100px
						padding-bottom: 20px
					
					@media( max-width: 1450px )
						height: auto

					@media( max-width: 1200px )
						height: auto

					@media( max-width: 849px )
						font-size: 16px

					@media( max-width: 594px )
						height: auto

				.btn-zaffiro-small
					position: absolute
					bottom: 38px

				@media ( max-width: 1700px )
					padding 40px 30px 75px

				@media ( min-width: 2200px )
					padding 40px 30px 75px

				@media ( max-width: 1024px )
					padding 40px 55px

				@media ( max-width: 736px )
					padding 40px 30px

				@media ( max-width: 549px )
					padding 36px 30px 75px