.newsletter-popup
	position: fixed
	top: 0
	left: 0
	background: rgba(255,255,255,0.9)
	width: 100%
	height: 100%
	z-index: 10000000000

	.newsletter-popup-container 
		width: 60%
		background: #4e576d
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		color: #fff

		header
			padding: 30px 30px 30px
			position: relative
			height: auto

			h1
				font-size: 30px
				margin: 0
				font-weight: 700

			> span 
				display: block
				background: transparent
				position: absolute
				top: 30px
				right: 30px
				width: 22px
				height: 22px
				color: #fff
				opacity: 1
			
		section
			padding: 30px 30px 15px
			font-size: 16px

		.sign
			padding: 30px 30px 60px

			svg
				width: 20px

			.st0
				fill: #fff !important
		
	.newsletter-form 
		margin-top: -3px		

header
	height: 165px
	width: 100%
	
	@media (max-width: 1600px)
		height: 135.5px

	@media (max-width: 736px)
		height: 70px
		padding: 0

		#header-right
			margin-top: 24.5px !important

	> div
		background-color: #fff
		width: 100%
		padding: 40px
		z-index: 1999999
		-webkit-transition: height .20s ease-in-out /* Safari */
		transition: height .20s ease-in-out

		@media (max-width: 1600px)
			padding: 25px 40px

		@media (max-width: 736px)
			height: 70px
			padding: 0

			> div
				padding-left: 30px !important
				padding-right: 30px !important

	&.fixed,
	&.scroll

		> div
			position: fixed

	&.scroll

		#header
			border-bottom: 1px solid #ededed

		> div
			height: 70px
			padding: 0 40px

			@media (max-width: 736px)
				padding: 0

	#header-logo
		height: 85px
		width: 172px
		overflow-x: hidden
		overflow-y: visible
		float: left
		position: relative
		-webkit-transition: width .20s ease-in-out /* Safari */
		transition: width .20s ease-in-out

		@media( max-width: 736px )
			height: 36px
			width: 99px
			overflow-y: hidden
			margin-top: 17px

		.logo-big
		.logo-small
			position: absolute
			-webkit-transition: opacity .20s ease-in-out /* Safari */
			transition: opacity .20s ease-in-out

		.logo-big
			width: 172px
			max-width: 100%
			top: 0
			left: 0
			opacity: 1

		.logo-small
			height: 18px
			top: 23px
			left: 0
			opacity: 0

	&.scroll

		> div

			#header-logo
				width: 50px
				margin-top: 2.5px
				min-height: 70px
			
				.logo-big
					opacity: 0

				.logo-small
					opacity: 1

			#header-right
				margin-top: 22px


	#header-right
		margin-top: 32px

		#header-socials
			color: light-steel-color
			font-weight: 900
			margin-right: 44px

			@media (max-width: 667px)
				display: none

			a
				color: light-steel-color
			
			img
				margin-left: 14px
				margin-top: -4px

		#header-menu-show
			
			cursor: pointer

			span.txt
				margin-left: 17px
				font-weight: 900
				color: blue-color

			.hamburger
				margin-top: 3px
				float: left
				width: 23px
				height: 14px
				position: relative
				-webkit-transform: rotate(0deg)
				-moz-transform: rotate(0deg)
				-o-transform: rotate(0deg)
				transform: rotate(0deg)
				-webkit-transition: .5s ease-in-out
				-moz-transition: .5s ease-in-out
				-o-transition: .5s ease-in-out
				transition: .5s ease-in-out
				cursor: pointer

				span
					display: block
					position: absolute
					height: 2px
					width: 50%
					background: blue-color
					opacity: 1
					-webkit-transform: rotate(0deg)
					-moz-transform: rotate(0deg)
					-o-transform: rotate(0deg)
					transform: rotate(0deg)
					-webkit-transition: .25s ease-in-out
					-moz-transition: .25s ease-in-out
					-o-transition: .25s ease-in-out
					transition: .25s ease-in-out

					&:nth-child(even) 
						left: 50%
						border-radius: 0 9px 9px 0

					&:nth-child(odd)
						left:0px
						border-radius: 9px 0 0 9px
					

					&:nth-child(1),
					&:nth-child(2)
						top: 0px

					&:nth-child(3),
					&:nth-child(4)
						top: 6px

					&:nth-child(5),
					&:nth-child(6)
						top: 12px

				&.open

					span

						&:nth-child(1),
						&:nth-child(6)
							-webkit-transform: rotate(45deg)
							-moz-transform: rotate(45deg)
							-o-transform: rotate(45deg)
							transform: rotate(45deg)

						&:nth-child(2),
						&:nth-child(5) 
							-webkit-transform: rotate(-45deg)
							-moz-transform: rotate(-45deg)
							-o-transform: rotate(-45deg)
							transform: rotate(-45deg)

						&:nth-child(1)
							left: 2px
							top: 2px

						&:nth-child(2)
							left: calc(50% - 2px)
							top: 2px

						&:nth-child(3)
							left: -50%
							opacity: 0

						&:nth-child(4)
							left: 100%
							opacity: 0

						&:nth-child(5)
							left: 2px
							top: 10px

						&:nth-child(6)
							left: calc(50% - 2px)
							top: 10px

	& + nav
		top: 165px

		@media (max-width: 1600px)
			top: 135px

		@media ( max-width: 736px )
			top: 70px

		.submenu
			height: calc( 100% - 165px )

	&.scroll

		& + nav
			top: 70px

			.submenu

				@media ( min-width: 1000px )
					height: calc( 100% - 70px )




nav
	position: fixed
	width: 100%
	height: 100%
	background-color: #fff
	z-index: 1999999
	left: 0
	display: none

	@media (max-width: 579px)
		overflow-y: auto
		height: calc( 100% - 70px )
	
	ul
		list-style-type: none
	
	& > ul

		// @media (min-width: 1000px) and (max-width: 1200px)
		// 	padding: 110px 0 210px
		// 	width: 35%
		// 	border-right: 2px solid #babec7

		@media (min-width: 1000px)
			padding: 110px 0 210px
			width: 30%
			border-right: 2px solid #babec7
		
		@media (min-width: 580px) and (max-width: 999px)
			display: table
			width: calc( 100% - 110px)
			border-bottom: 2px solid #babec7
			margin: 0 55px
			padding: 0

		@media (max-width: 580px)
			margin: 0 55px
			padding: 0
		
		li
			@media (min-width: 1000px) and (max-width: 1140px)
				padding-left: 20px

			@media (min-width: 1140px) and (max-width: 1300px)
				padding-left: 40px

			@media (min-width: 1300px) 
				padding-left: 76px


			@media (min-width: 580px) and (max-width: 999px)
				display: table-cell
				text-align: center
				width: 14%
				
				&:nth-child(7) 
					width: 15.5%

			@media (max-width: 580px)
				clear: both

			a

				@media (min-width: 1000px)
					font-size: 26px
					padding-left: 30px
				
				@media (min-width: 580px) and (max-width: 999px)
					font-size: 15px
					padding: 29px 10px
					display: block

				@media (max-width: 580px)
					padding-left: 0

				color: blue-color
				
				&:hover
					font-weight: bold
					color: blue-color
					
				&:hover,
				&:visited,
				&:active
					text-decoration: none

			.submenu
				padding: 20px 3%

				.row
					display: flex
					flex-wrap: wrap

					@media (min-width: 1200px)
						padding-top: 65px

					.col-xs-3

						&:nth-last-child( -n + 4 )
							
							table.menu-ico-list
								margin-bottom: 0 !important

						@media (min-width: 645px) and (max-width: 1000px)
							width: 19.5%

						@media (min-width: 1200px)
							width: 19.5%

						@media (max-width: 426px)
							width: 33%
						@media (min-width: 999px) and (max-width: 1200px)
							width: 24.5%
						@media (min-width: 580px) and (max-width: 645px)
							width: 24.5%





				@media (min-width: 580px)
					overflow-y: auto
					position: absolute
				
				// @media (min-width: 1000px) and (max-width: 1200px)
				// 	left: 35%
				// 	top: 0
				// 	width: 69%

				@media (min-width: 1000px)
					top: 0
					left: 30%
					width: 69%
				
				@media (min-width: 580px) and (max-width: 999px)
					top: 100px
					left: 0
					width: 100%
					padding: 0 55px

				@media (max-width: 579px)
					border-bottom: 2px solid #babec7
					border-top: 2px solid #babec7
					position: absolute
					top: 310px
					height: auto
					left: 0
					margin: 30px 20px 40px 20px

					.col-xs-3
						width: 33%

				a
					&:hover,
					&.active
						font-weight: bold

					font-size: 12px
					font-weight: normal

					> div
						padding: 0

					table.menu-ico-list
						
						width: 100%

						@media (min-width: 1000px)
							margin-bottom: 40px
							height: 53px

						@media (min-width: 580px) and (max-width: 999px)
							height: 106px

						@media(max-width: 579px)
							text-align: center

						tr

							&:last-child

								@media (min-width: 1000px)
									display: none

							&:first-child

								td

									&:last-child
								
										@media (max-width: 999px)
											display: none

							td

								@media (max-width: 999px)
									height: 53px
									
								@media( min-width: 1000px )
									img
										height: 53px
								@media( min-width: 500px ) and (max-width: 999px)
									img
										height: 100px
								@media(max-width: 499px)
									img
										height: 60px

						.ico
							width: 53px
							text-align: center

							img
								max-width: 100%
								max-height: 100%

						.name
							padding: 0 12px
							word-wrap: break-word


		> li
			
			#header-socials

				a
					padding-left: 19px

				a:first-child
					padding-left: 0
			
			@media( min-width: 580px )
				#header-socials
					display: none

			> a

				@media (min-width: 1000px)
					line-height: 52px

				@media (min-width: 580px) and (max-width: 714px)
					font-size: 12px
					margin: 0
					padding: 29px 0

				@media (max-width: 580px)
					margin: 20px 0
					display: block

				&:hover,
				&.active
					font-weight: bold

					@media (min-width: 1000px)
						background:url('../img/main/zaffiro-sign.svg') no-repeat left top 11px
						background-size: 16px 16px