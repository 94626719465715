#kontakt
	
	.top-normal-img
		&#kontakt-mapa-wrapper
			height: auto

		.container-fluid

			@media (min-width: 736px)
				padding-right: 0

				.row
					margin-right: 0

		a
			text-decoration: none
			color: blue-color

		.txt-container
			color: blue-color
			font-size: 20px
			
			@media( max-width: 1024px )
				padding: 45px 55px
			
			@media( max-width: 736px )
				padding: 45px 30px

			@media( max-width: 414px )
				padding: 30px

			h1
				color: steel-color

			.nazwa-firmy
				display: block
				font-weight: 900
				margin-bottom: 24px

		.e-contact
			margin-top: 24px

			span
				display: block
				clear: both

		.additional-data
			margin-top: 24px

			@media( max-width: 736px )

				table
					margin: 0 auto

			tr

				td
					&:nth-child(2)
						padding-left: 10px

	.standard-container-left
		clear: both
		height: auto
		min-height: 0
		padding-bottom: 30px

		.row
			height: auto

			.txt-container
				padding: 92px 56px
				
				@media( max-width: 1024px )
					padding: 45px 55px 0

				@media( max-width: 736px )
					padding: 45px 30px 0

				h1
					margin: 0

			.contact-form
				padding: 92px 0
				
				@media( max-width: 1024px )
					padding: 50px 55px 0
				
				@media( max-width: 736px )
					padding: 50px 30px 0

				input:nth-child(2)
					margin-top: 18px

				.contact-info
					margin-bottom: 30px

					@media( min-width: 992px )
						margin-bottom: 110px

					.image

						@media( max-width: 736px )
							padding-bottom: 30px

						img
							max-width: 100%
							max-height: 100%

					.desc,
					.contact-1,
					.contact-2
						margin-bottom: 30px

				textarea
					height: 94px
					@media( max-width: 767px )
						margin-top: 18px

				button
					margin-top: 37px
					margin-left: 15px

	.centrum-prasowe

		> .container-fluid

			.row
				@media ( max-width: 1024px )
					margin-right: -15px

				.logos

					.row

						> div
							border-radius: 5px
							margin: 0 auto

							> .row

								> div
									padding: 45px calc( (50% - 197px) / 2 )

									@media ( max-width: 767px )
										padding: 45px 30px

									img
										width: 197px

									> div
										display: flex
										flex-wrap: nowrap

										@media ( max-width: 767px )
											display: block

										.download
											display: inline-block
											padding: 5px 7px 7px
											border: 2px solid border-gray-color
											border-radius: 10px
											color: blue-color
											font-weight: bold
											font-size: 16px
											margin-right: 28px

											&:last-child
												margin-right: 0

							&.dark
								background: blue-color

								> .row

									> div

										> div

											.download
												border-color: white
												color: white