#produkt
	
	color: blue-color
	
	p
		font-size: 24px
	

	.standard-container-left,
	.standard-container-right,
	.standard-container
		clear: both
		overflow: hidden
		height: auto
		
		&.columns-p

			> .container-fluid

				> .row
					height: auto
					margin-right: -15px


	.top-normal-img
		height: 80vh
		background-color: #f5fafe
		
		@media( max-width: 1600px )
			height: auto

		.txt-container
			height: 80vh
			color: blue-color

			@media( max-width: 991px )
				height: 50vh
				padding: 45px 53px

			@media( max-width: 768px )
				padding: 45px 55px

			@media( max-width: 736px )
				padding: 45px 30px
				
			@media( max-width: 435px )
				height: auto

				a.button-group			
					position: relative
					left: 0
			
			@media( max-width: 413px )
				padding: 50px 30px

			@media( max-height: 414px )
				height: 120vh

			p
				font-size: 20px
			
			h1, h1 p
				font-size: 40px

			h1
				margin-bottom: 30px
					
			a.button-group			
				position: absolute
				bottom: 50px
				left: 40px

				@media( max-width: 435px )
					bottom: 0;

			img.pikto
				width: 110px
				margin-bottom: 30px

		.img-container
			height: 80vh
			background-repeat: no-repeat
			background-position-x: center
			background-position-y: center
			background-size: cover
			
			@media( max-width: 991px )
				height: 450px
			
	.standard-container-left
		
		.txt-container
			float: left
			padding-left: 250px

		.img-container
			float: right
	
	.standard-container-right
		
		.txt-container
			padding-right: 250px			

	.standard-container-left,
	.standard-container-right
			
		&.columns-p
						
			.txt-container
				
				padding: 74px 120px 0
				
				@media( max-width: 667px )
					padding: 10px 15px
					margin-bottom: 70px

				@media( max-width: 435px )
					padding: 50px 30px

				@media( min-width: 991px ) and ( max-width: 1250px )
					padding: 0 54px

				@media( min-width: 769px ) and ( max-width: 990px )
					padding: 0 54px 60px

				@media( min-width: 737px ) and ( max-width: 768px )
					padding: 0 55px 60px

				@media( min-width: 436px ) and ( max-width: 736px )
					padding: 0 30px 60px
				
				h1
					margin-top: 65px
					margin-bottom: 65px
					color: blue-color
					font-size: 61px
					
					@media( max-width: 1700px )
						font-size: 46px

					@media( min-width: 1100px ) and ( max-width: 1250px )
						margin-top: 90px

					@media( min-width: 991px ) and ( max-width: 1099px )
						margin-top: 60px
						margin-bottom: 60px

					@media( max-width: 736px )
						margin: 45px 0

					@media( max-width: 435px )
						margin: 0 0 48px
					
					p
						font-size: 61px
						
						@media( max-width: 1700px )
							font-size: 46px
						
						@media( max-width: 676px )
							font-size: 39px
						
				p, ul, li
					font-size: 22px
					
					@media( max-width: 1700px )
						font-size: 20px
					
			.img-container
				background-size: cover
				background-repeat: no-repeat
				height: 700px
				
				@media( max-width: 676px )
					height: 350px
					background-size: cover
					
	.standard-container		
		
		h1
			color: blue-color
			
			@media( max-width: 736px )
				margin-top: 45px !important
				margin-bottom: 30px
			
			@media( max-width: 435px )
				margin-top: 0 !important

			p
				font-size: 48px
				
				@media( max-width: 1200px )
					font-size: 43px
				
				@media( max-width: 676px )
					font-size: 39px

		p
			font-size: 22px
			color: blue-color
			padding-bottom: 20px
			
			@media( max-width: 1700px )
				font-size: 20px

		.btn-zaffiro
			position: relative
			bottom: 0
			left: 0
			line-height: 14px

		&.gray

			h1
				margin-top: 118px

		&.grupa
			padding: 0
			height: 700px

			> .row

				@media( min-width: 992px )
					padding: 0 54px 0 41px

				@media( min-width: 1150px )
					padding: 0 60px 0 120px
			
			@media( max-width: 991px )
				padding-top: 0
				height: auto
				
				.standard-container
					h1
						margin-top: 35px
			
			.text
				padding-right: 135px
				margin-top: 350px
				transform: translateY(-50%)
				
				@media( max-width: 991px )
					padding: 60px 120px 30px
					margin-top: 0
					transform: none

				@media (min-width: 737px) and ( max-width: 991px )
					padding: 60px 54px 30px

				@media (min-width: 436px) and ( max-width: 736px )
					padding: 0 30px 30px

				@media( max-width: 1150px) and (min-width: 992px)
					padding-right: 35px

				@media( max-width: 435px )
					padding: 50px 30px
				
				ul, li, h2, h4
					font-size: 22px
					
					@media( max-width: 1700px )
						font-size: 20px
					
				ul
					padding-bottom: 30px

				.serce
					@media ( max-width: 425px )
						display: none

			.suwak-outer-container

				@media( max-width: 435px )
					padding: 0 55px 55px
					
				.suwak-container
					
					overflow-y: hidden
					position: relative
					width: 100%
					padding: 0 10px
					
					@media( max-width: 991px )
						margin-left: 0
					
					.left, .right
						top: calc( 50% - 30px )
						position: absolute
						cursor: pointer
						z-index: 99
						height: 60px
						
					.left
						left: 0
						
					.right
						right: 0					
					
					.suwak-row
					
						white-space: nowrap
						overflow-x: auto
						height: auto
						margin-bottom: -20px
						
						@media( min-width: 551px )
							height: 720px
						
						.suwak-two-items-container
							display: inline-block
							width: 49.5%
							
							@media( max-width: 550px )
								width: 100%

							.example
								height: 350px
								margin-top: 0
								text-align: center
								width: 100%
								cursor: pointer
								position: relative
								padding-top: 20px

								div
									height: 250px

								&:hover

									.description
										display: block

								img
									max-width: 100%
									max-height: 100%

								.name

									p
										padding: 0
										margin: 0

								.description
									background: rgba(242, 247, 251, 0.98)
									padding: 61px 20px 0 20px
									position: absolute
									top: 0
									left: 0
									width: 100%
									height: 100%
									white-space: normal

									.title
										display: block
										font-size: 16px
										font-weight: bold

										span
											display: block

									p
										font-size: 16px

	.piktogramy
		padding: 135px 0

		@media (max-width: 1600px)
			min-height: 0

		@media (min-width: 1601px)
			padding: 270px 0

		@media (max-width: 736px)
			padding: 35px 0

		.flex-container
			display: flex
			flex-wrap: wrap
			justify-content: center

			.piktogram
				min-width: 120px
				text-align: center
				margin: 0 74px
				width: calc( 16% - 148px )

				@media(min-width: 2000px)
					margin: 0 40px

				@media(min-width: 1340px) and (max-width: 2000px)
					margin: 0 50px

				@media(max-width: 1340px) and (min-width: 1072px)
					    margin: 0 46px;
				
				@media(max-width: 750px) and (min-width:600px)
						margin: 0 0px !important;

				img
					width: 120px
					height: 191px

				@media ( max-width: 803px )
					margin: 0 15px
					width: calc( 20% - 148px )

					img
						width: 90px

	.film
		display: flex
		justify-content: center
		margin-bottom: 10%


		.flex-container
			display: flex
			flex-wrap: wrap
			justify-content: center
			width: 80%;

			iframe
				width: 100%
    			height: 100%

			> div
				width: 100%;
				text-align: center

			.title

				h3
					font-weight: bold;
					margin-top: 30px;
					font-size: 20px

			.description p
				margin-top: 10px
				font-size: 20px

			iframe
				max-width: 100%


	.dodatkowe-rysunki
		padding: 135px 0

		@media (max-width: 435px)
			padding: 0

		@media (max-width: 1600px)
			min-height: 0

		@media (min-width: 1601px)
			padding: 270px 0

		.container

			@media (max-width: 413px )
				padding: 50px 30px

			.rysunek
				width: 30.333%
				max-width: 100%;
				text-align: center
				float: left
				padding: 0 0 55px
				margin-left: 2%

				&:last-child
					margin-right: 0

				@media (max-width: 435px)
					width: 100%

				img
					width: 288px
					max-width: 100%

				.nazwa,
				.opis
					display: block

				.nazwa
					font-weight: bold
					margin-top: 30px

				.opis
					margin-top: 10px

	.powiazane-produkty
		padding: 0

		@media (max-width: 435px)
			margin: 0
			padding: 0

		#lista-produktow
			
			@media (max-width: 1024px)

				> div

					> .row

						> div
							padding: 0
							height: auto !important
							padding: 50px 55px

							.txt
								padding: 0
								height: auto

								h2
									height: auto
									margin-bottom: 48px

								.btn-zaffiro
									position: relative
									margin-top: 20px
									bottom: 0
			
				.produkt-col
					overflow: hidden
				
					&.start

						h1
							margin-bottom: 0 !important

				.img
					display: none
			
			@media (max-width: 736px)

				> div

					> .row

						> div
							padding: 50px 30px

				
			@media (max-width: 650px)

				> div

					> .row

						> div

							.txt

								h2
									margin-bottom: 14px

								
							

	.bottom-imgs

		padding-bottom: 100px
		background-color: light-gray-color
		margin-bottom: 0
		
		@media( max-width: 676px )
			margin-bottom: 0

		.bottom-img-container
			padding: 0
			
			img
				max-width: 100%